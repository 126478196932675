import React from 'react';
import './Servicess.css';
import ServicesCards from '../../localData/ServicesData.js';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Servicess = () => {
    const { t } = useTranslation();
    const servicesData = ServicesCards(); // Ensure this function does not use deprecated lifecycle methods

    return (
        <section id='services' className='services'>
            <div className='sectionH'>
                <h2><span> {t("servNav")} </span></h2>
            </div>
            <div className='container-'>
                <div className='serv-items catgitems'>
                    {servicesData.slice(0, 6).map(service => (
                        <div className='catcontainer' key={service.id}>
                            <Link to={`/services/${service.id}/${service.gen_category}/${service.Serv_name}`}>
                                <div className='serv-item catgitem'>
                                    <img src={service.img} alt='img' />
                                    <h2 className='serv-content catgcontent'>{service.Serv_name}</h2>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <div className='section-more-btn more-btn'>
                <Link to="/services"> {t("Smore-btn")} </Link>
            </div>
        </section>
    );
}

export default Servicess;
