import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import i18next from 'i18next';
import './CategoryPage.css';
import '../..//Components/CategoryItem/CategoryItem.css';
import CategoryItem from '../../Components/CategoryItem/CategoryItem';
import { IoHome } from "react-icons/io5";
import { Link } from 'react-router-dom';
import showCase from "../../Assets/showCase.jpg"
import { Helmet } from 'react-helmet';

const CategoryPage = () => {
    const { gen_category } = useParams();
    const [categoryData, setCategoryData] = useState([]);
    const currentLanguage = i18next.language;
    useEffect(() => {
        window.scrollTo(0, 0);

        axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/categories/${gen_category}?lang=${currentLanguage}`)
            .then(response => {
                const formattedData = response.data.map(item => ({
                    ...item,
                    categoryImage: `${item.categoryImage}`
                }));
                setCategoryData(formattedData);
            })
            .catch(error => {
                console.error('Error fetching category data:', error);
            });
    }, [gen_category, currentLanguage]);

    return (
        <div className='catygorypage'>
            <Helmet>
                <title>{gen_category}</title>
                <link rel="canonical" href="https://trendlineeg.com/" />
                <meta property="og:image" content={showCase} />
                <meta property="og:title" content={`TrendLine ${gen_category}`} />
                <meta property="og:url" content="https://trendlineeg.com" />
                <meta property="og:description" lang="ar"
                    content="شركة ترندلاين متخصصة في تقديم حلول منخفضة التيار المتكاملة بما في ذلك أنظمة المراقبة بالفيديو عالية الدقة، وأنظمة التحكم في الوصول، وأنظمة الإنذار بالحريق، وأنظمة الأمان، والخوادم ووحدات التخزين الشبكية، وجدران الحماية، واللاب توب وأجهزة الكمبيوتر، وقطع الكمبيوتر، والشاشات والتلفزيونات، والشاشات التفاعلية، والألياف البصرية وUTP، وحلول WiMax وUbiquiti. تقدم الشركة خدمات البيع المباشر، التوصيل، التركيب، والبرمجة لعملاء الإلكترونيات، اللاب توب، السيرفرات، الكمبيوترات التجميع، السمارت هوم، جدران الحماية، أنظمة المراقبة، إدارة المحال التجارية، إدارة الفنادق، التوصيل الشبكي بالكابل، الواي فاي، الفايبر أوبتيكال، خدمة العملاء، السنترال الداخلي، ربط الفروع، الفيديو كول، الإنتركوم المرئي والصوتي، الساوند المركزي، الدش والستالايت، وأنظمة الألواح الشمسية" />
                <meta property="og:description" lang="en"
                    content="Trendline specializes in providing integrated low current solutions, including HD video surveillance, access control systems, fire alarm systems, security systems, servers and NAS storage, firewalls, laptops and PCs, PC hardware, monitors and TVs, interactive screens, fiber optics and UTP, and WiMax and Ubiquiti solutions. The company offers direct sales, delivery, installation, and programming services for electronics, laptops, servers, custom-built computers, smart home, firewalls, surveillance systems, retail management, hotel management, wired, Wi-Fi, and fiber optic network solutions, customer service systems, internal PBX, branch connectivity, video call systems, intercom (video and audio), central sound systems, satellite dishes, and solar panels." />

            </Helmet>
            <div className='pageH container d-flex' dir={i18next.dir()}>
                <Link to={"/"}> <IoHome /> </Link>
                &gt;
                <p>{gen_category}</p>
            </div>

            <div id='catygoryitems' className='catygoryitems'>
                <div className='catygory-main-container'>
                    {categoryData.map(item => (
                        <CategoryItem key={item.id} category_name={item.name} gen_category={gen_category} img={item.categoryImage} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CategoryPage;
