import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
import './Contact.css'
import { LiaShippingFastSolid } from "react-icons/lia";
import { BiMedal } from "react-icons/bi";
import { TbUserShield } from "react-icons/tb";

// import contactImg1 from '../../Assets/contactIcon1.png'
// import contactImg2 from '../../Assets/contactIcon2.png'
// import contactImg3 from '../../Assets/contactIcon3.png'
import { t } from 'i18next';

function Contact() {


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();
        const servicesId = "service_bcpytho";
        const templateId = "template_ppfinfh";
        const publikKey = "y01dtFdHmKYNdH0Rx";

        const templateParams = {
            from_name: name,
            from_email: email,
            phone_num: phone,
            message: message
        };
        emailjs.send(servicesId, templateId, templateParams, publikKey)
            .then((response) => {
                alert("Email sent successfully \n تم الإرسال بنجاح", response);
                setName('');
                setEmail('');
                setPhone('');
                setMessage('');
            })
            .catch((error) => {
                alert("Failed to send message \n تعذر إرسال الرسالة", error);
            });
    };

    return (
        <section id='contact'  >
            <div className='containerr contact d-flex'>

                <div className='contact-left'>
                    <div className="left-content">
                        <div className='contact-card'>
                            <div className="contactIconsBG">
                                <LiaShippingFastSolid />
                            </div>
                            <div className='contact-card-content'>
                                <h5>{t("Contcard1H")} </h5>
                                <p>{t("Contcard1P")}</p>
                            </div>
                        </div>

                        <div className='contact-card'>
                            <div className="contactIconsBG">
                                <BiMedal />
                            </div>
                            <div className='contact-card-content'>
                                <h5>{t("Contcard2H")}</h5>
                                <p>{t("Contcard2P")}</p>
                            </div>
                        </div>

                        <div className='contact-card'>
                            <div className="contactIconsBG">
                                <TbUserShield />
                            </div>
                            <div className='contact-card-content'>
                                <h5>{t("Contcard3H")}</h5>
                                <p>{t("Contcard3P")}</p></div>
                        </div>
                    </div>
                </div>

                <div className='contact-right'>
                    <div className="right-content">
                        <div>
                            <h4> {t("contactH")}</h4>
                        </div>
                        <form onSubmit={sendEmail} className='emailForm'>

                            <div className='top-col'>
                                <input
                                    type="text"
                                    name='name'
                                    placeholder={t("Contname")}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                                <input
                                    type="email"
                                    name='email'
                                    placeholder={t("Contemail")}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <input
                                    name='Phone'
                                    placeholder={t("Contphone")}
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    required
                                />
                                <textarea
                                    placeholder={t("Contmsg")}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)} required />
                            </div>
                        </form>
                        <div className='send-btn'><button type='submit' className='sub-btn'>{t("send")}</button></div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Contact