import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput
} from 'mdb-react-ui-kit';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
            navigate('/adminpanal'); // Automatically redirect if token exists
        }
    }, [navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/login`, {
                email,
                password
            });

            const { token } = response.data;
            sessionStorage.setItem('token', token);
            setIsLoggedIn(true);
            navigate('/adminpanal'); // Redirect to the admin panel
        } catch (err) {
            setError('Login failed. Please check your credentials and try again.');
            console.error(err);
        }
    };

    const handleLogout = () => {
        sessionStorage.removeItem('token');
        setIsLoggedIn(false);
    };

    if (isLoggedIn) {
        return (
            <div className="login">
                <h2>You are logged in!</h2>
                <MDBBtn outline className='mx-2 px-5' color='white' size='lg' onClick={handleLogout}>
                    Logout
                </MDBBtn>
            </div>
        );
    }

    return (
        <div className="login">
            <MDBContainer fluid>
                <MDBRow className='d-flex justify-content-center align-items-center h-100'>
                    <MDBCol col='12'>
                        <MDBCard className='my-5 mx-auto' style={{ maxWidth: '400px' }}>
                            <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>
                                <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
                                <p className="mb-5">Please enter your login and password!</p>

                                {error && <p className="text-danger">{error}</p>}

                                <MDBInput
                                    wrapperClass='mb-4 mx-5 w-100'
                                    labelClass='text-white'
                                    label='Email address'
                                    id='formControlLg'
                                    type='email'
                                    size="lg"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <MDBInput
                                    wrapperClass='mb-4 mx-5 w-100'
                                    labelClass='text-white'
                                    label='Password'
                                    id='formControlLg'
                                    type='password'
                                    size="lg"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />

                                <MDBBtn
                                    outline
                                    className='mx-2 px-5'
                                    color='white'
                                    size='lg'
                                    onClick={handleLogin}
                                >
                                    Login
                                </MDBBtn>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    );
}

export default Login;
