import React, { useEffect, useRef, useState } from 'react';
import './Navbar.css';
import '../sideBar/sideBar.css'
import logo from '../../Assets/logo.png';
import { RiArrowDropDownLine } from "react-icons/ri";
import { FaWhatsapp, FaFacebookF, FaInstagram } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";
import { Link as LinkS } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import NavDropData1 from '../../localData/navDopeData1';
import NavDropData2 from '../../localData/navDopeData2';
import NavDropData3 from '../../localData/navDopeData3';
import Search from '../search/Search';
import { FiSearch } from "react-icons/fi";

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const dropData1 = NavDropData1();
    const dropData2 = NavDropData2();
    const dropData3 = NavDropData3();
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [showDropdown, setShowDropdown] = useState({
        dropdown1: false,
        dropdown2: false,
        dropdown3: false,
        dropdown4: false,
        dropdown5: false,
        dropdown6: false,
        dropdown7: false,
    });

    const dropdownRefs = {
        dropdown1Ref: useRef(null),
        dropdown2Ref: useRef(null),
        dropdown3Ref: useRef(null),
        dropdown4Ref: useRef(null),
        dropdown5Ref: useRef(null),
        dropdown6Ref: useRef(null),
        dropdown7Ref: useRef(null),
    };

    const toggleDropdown = (dropdown) => {
        setShowDropdown((prevState) => ({
            ...prevState,
            [dropdown]: !prevState[dropdown],
        }));
    };

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    useEffect(() => {
        const checkIfClickedOutside = (event) => {
            if (showDropdown.dropdown1 && dropdownRefs.dropdown1Ref.current && !dropdownRefs.dropdown1Ref.current.contains(event.target)) {
                toggleDropdown('dropdown1');
            } else if (showDropdown.dropdown2 && dropdownRefs.dropdown2Ref.current && !dropdownRefs.dropdown2Ref.current.contains(event.target)) {
                toggleDropdown('dropdown2');
            } else if (showDropdown.dropdown3 && dropdownRefs.dropdown3Ref.current && !dropdownRefs.dropdown3Ref.current.contains(event.target)) {
                toggleDropdown('dropdown3');
            } else if (showDropdown.dropdown4 && dropdownRefs.dropdown4Ref.current && !dropdownRefs.dropdown4Ref.current.contains(event.target)) {
                toggleDropdown('dropdown4');
            } else if (showDropdown.dropdown5 && dropdownRefs.dropdown5Ref.current && !dropdownRefs.dropdown5Ref.current.contains(event.target)) {
                toggleDropdown('dropdown5');
            } else if (showDropdown.dropdown6 && dropdownRefs.dropdown6Ref.current && !dropdownRefs.dropdown6Ref.current.contains(event.target)) {
                toggleDropdown('dropdown6');
            } else if (showDropdown.dropdown7 && dropdownRefs.dropdown7Ref.current && !dropdownRefs.dropdown7Ref.current.contains(event.target)) {
                toggleDropdown('dropdown7');
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDropdown]);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('lng', lng);
        window.history.replaceState(null, null, `?${urlParams.toString()}`);
        window.location.reload();
    };

    const languages = [
        { code: "en", lang: "EN" },
        { code: "ar", lang: "AR" },
    ];

    const location = useLocation();
    const [onHomePage, setOnHomePage] = useState(true);

    useEffect(() => {
        setOnHomePage(location.pathname === '/');
    }, [location]);

    useEffect(() => {
        const contactElement2 = document.querySelector('#sec-navbar');
        if (contactElement2) {
            const direction = i18n.dir();
            contactElement2.dir = direction;
        }
    }, [i18n]);

    const searchInputRef = useRef(null);

    const handleSearchIconClick = () => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    };

    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            // Check if the user is scrolling up or down
            if (prevScrollPos > currentScrollPos) {
                setVisible(true);
            } else {
                setVisible(false);
            }
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos]);

    return (
        <div id='navbar'>
            <div className="navbar">
                <div className='main-nav' onClick={(e) => e.stopPropagation()}>
                    <div className='nav-menu-btn' onClick={toggleSidebar}>
                        <HiMenu />
                    </div>
                    <div className='lng-btn nav-hide'>
                        {languages.map((lng) => (
                            <button
                                className={lng.code === i18n.language ? "selected" : ""}
                                key={lng.code}
                                onClick={() => changeLanguage(lng.code)}
                            >
                                {lng.lang}
                            </button>
                        ))}
                    </div>
                    <ul className="nav-items nav-hide">
                        {onHomePage ? (
                            <li className="nav-item"><LinkS to="services"> {t("servNav")}</LinkS></li>
                        ) : (
                            <li className="nav-item"><Link to="/">{t("home")}</Link></li>
                        )}

                        <li className="nav-item dropdown" ref={dropdownRefs.dropdown1Ref} onClick={() => toggleDropdown('dropdown1')}>
                            <i><RiArrowDropDownLine /></i>
                            {t("Fcol2")}
                            {showDropdown.dropdown1 && (
                                <div className="dropdown-menu">
                                    <p><strong>+201281717577</strong></p>
                                    <a href="https://api.whatsapp.com/send?phone=201281717577"
                                        target="_blank"
                                        rel="noreferrer" className='cont-btn'>{t("Fcol2")}</a>
                                    <div className='links'>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href="https://api.whatsapp.com/send?phone=201281717577"><i><FaWhatsapp /></i></a>
                                        <a target="_blank"
                                            rel="noreferrer"
                                            href="https://www.facebook.com/Trendlineeg/"><i><FaFacebookF /></i></a>
                                        <a target="_blank"
                                            rel="noreferrer"
                                            href="https://www.instagram.com/trendlineeg/"><i><FaInstagram /></i></a>
                                    </div>
                                </div>
                            )}
                        </li>
                    </ul>
                    <div className="s">
                        <Search ref={searchInputRef} />
                    </div>
                    <div className="logo">
                        <a href="/"><img src={logo} alt="Logo" /></a>
                    </div>
                </div>
            </div>
            <div id='sec-navbar' className={`sec-navbar nav-hide ${visible ? '' : 'navhidden'}`}>
                <div className='containerr'>
                    <ul className="nav-items">
                        <li className="nav-item dropdown" ref={dropdownRefs.dropdown4Ref} onClick={() => toggleDropdown('dropdown4')}>
                            {t("sec-nav1")}
                            <i><RiArrowDropDownLine /></i>
                            {showDropdown.dropdown4 && (
                                <div className="dropdown-menu" style={{ width: "120%" }}>
                                    <ul>
                                        {dropData1.map((item, index) => (
                                            <li key={index}><Link to={`category/${item.nav_d1_category}`}>{item.nav_d1_category}</Link></li>
                                        ))}

                                    </ul>
                                </div>
                            )}
                        </li>
                        <li className="nav-item dropdown" ref={dropdownRefs.dropdown3Ref} onClick={() => toggleDropdown('dropdown3')}>
                            {t("sec-nav2")}
                            <i><RiArrowDropDownLine /></i>
                            {showDropdown.dropdown3 && (
                                <div className="dropdown-menu">
                                    <ul>
                                        {dropData2.map((item, index) => (
                                            <li key={index}><Link to={`category/${item.gen_category}/${item.nav_d2_category}/products`}>{item.nav_d2_category}</Link></li>
                                        ))}
                                        <li><Link to={`category/${dropData2[0].gen_category}`}>{t("more-btn")}</Link></li>
                                    </ul>
                                </div>
                            )}
                        </li>
                        <li className="nav-item dropdown" ref={dropdownRefs.dropdown2Ref} onClick={() => toggleDropdown('dropdown2')}>
                            {t("sec-nav3")}
                            <i><RiArrowDropDownLine /></i>
                            {showDropdown.dropdown2 && (
                                <div className="dropdown-menu">
                                    <ul>
                                        {dropData3.map((item, index) => (
                                            <li key={index}><Link to={`category/${item.gen_category}/${item.nav_d3_category}/products`}>{item.nav_d3_category}</Link></li>
                                        ))}
                                        <li><Link to={`category/${dropData3[0].gen_category}`}>{t("more-btn")}</Link></li>
                                    </ul>
                                </div>
                            )}
                        </li>
                        <li className="nav-item"><Link to="/offers">{t("sec-nav4")}</Link></li>
                        <li className="nav-item"><Link to="/bestseller">{t("sec-nav5")}</Link></li>
                        <li className="nav-item"><Link to="/aboutus">{t("about")}</Link></li>
                    </ul>
                </div>
            </div>
            <div className='fakenave'></div>

            {sidebarVisible && (
                <div className='sidenav'>
                    <div className='sidenav-content'>
                        <div style={{ width: "100%" }}>
                            <div>
                                <div className='lng-btn sied-lngBtn'>
                                    {languages.map((lng) => (
                                        <button
                                            className={lng.code === i18n.language ? "selected" : ""}
                                            key={lng.code}
                                            onClick={() => changeLanguage(lng.code)}
                                        >
                                            {lng.lang}
                                        </button>
                                    ))}
                                </div>
                                <div className='MsearchIcon' onClick={() => { handleSearchIconClick(); toggleSidebar(); }}>
                                    <FiSearch />
                                </div>
                            </div>
                            <div className="sec-navbar" dir={i18n.dir()}>
                                <div className='containerr'>
                                    <ul className="nav-items">
                                        {onHomePage ? (
                                            <li className="nav-item"><Link to="/services"> {t("servNav")}</Link></li>
                                        ) : (
                                            <li className="nav-item"><Link to="/">{t("home")}</Link></li>
                                        )}

                                        <li className="nav-item dropdown" ref={dropdownRefs.dropdown5Ref} onClick={() => toggleDropdown('dropdown5')}>
                                            {t("sec-nav1")}
                                            <i><RiArrowDropDownLine /></i>
                                            {showDropdown.dropdown5 && (
                                                <div className="dropdown-menu">
                                                    <ul>
                                                        {dropData1.map((item, index) => (
                                                            <li key={index}><Link to={`category/${item.nav_d1_category}`}>{item.nav_d1_category}</Link></li>
                                                        ))}
                                                        <li><Link to={`category/${dropData3[0].gen_category}`}>{t("more-btn")}</Link></li>
                                                    </ul>
                                                </div>
                                            )}
                                        </li>
                                        <li className="nav-item dropdown" ref={dropdownRefs.dropdown6Ref} onClick={() => toggleDropdown('dropdown6')}>
                                            {t("sec-nav2")}
                                            <i><RiArrowDropDownLine /></i>
                                            {showDropdown.dropdown6 && (
                                                <div className="dropdown-menu">
                                                    <ul>
                                                        {dropData2.map((item, index) => (
                                                            <li key={index}><Link to={`category/${item.gen_category}/${item.nav_d2_category}/products`}>{item.nav_d2_category}</Link></li>
                                                        ))}
                                                        <li><Link to={`category/${dropData2[0].gen_category}`}>{t("more-btn")}</Link></li>
                                                    </ul>
                                                </div>
                                            )}
                                        </li>
                                        <li className="nav-item dropdown" ref={dropdownRefs.dropdown7Ref} onClick={() => toggleDropdown('dropdown7')}>
                                            {t("sec-nav3")}
                                            <i><RiArrowDropDownLine /></i>
                                            {showDropdown.dropdown7 && (
                                                <div className="dropdown-menu">
                                                    <ul>
                                                        {dropData3.map((item, index) => (
                                                            <li key={index}><Link to={`category/${item.gen_category}/${item.nav_d3_category}/products`}>{item.nav_d3_category}</Link></li>
                                                        ))}
                                                        <li><Link to={`category/${dropData3[0].gen_category}`}>{t("more-btn")}</Link></li>
                                                    </ul>
                                                </div>
                                            )}
                                        </li>
                                        <li className="nav-item"><Link to="/offers">{t("sec-nav4")}</Link></li>
                                        <li className="nav-item"><Link to="/bestseller">{t("sec-nav5")}</Link></li>
                                        <li className="nav-item"><Link to="/aboutus">{t("about")}</Link></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='sidefooter'>
                            <div className='SFH6'>
                                <h6>{t("Fcol2")}</h6>
                                <div className='under-border'></div>
                            </div>
                            <div className='SFH6 SFH7'>
                                <h6>{t("phonep")}</h6>
                                <div className='under-border'></div>
                            </div>
                            <div className='sideFoter'>

                                <div className='links'>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://api.whatsapp.com/send?phone=201281717577"><FaWhatsapp /></a>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.facebook.com/Trendlineeg/"><FaFacebookF /></a>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.instagram.com/trendlineeg/"><FaInstagram /></a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='sidenav-overlay' onClick={toggleSidebar}></div>
                </div>
            )}
        </div>
    );
};

export default Navbar;
