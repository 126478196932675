/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react'
import './Footer.css';
import { useTranslation } from 'react-i18next';
import { BsFillPhoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { TbClockHour4Filled } from "react-icons/tb";
import pay from "../../Assets/pay.png"
import { Link } from 'react-router-dom';

function Footer() {
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();

    const addBr = (text) => {
        if (!text) return '';

        // Remove text between double braces {{ }}
        const withoutBracesText = text.replace(/\{\{.*?\}\}/gs, '');

        // Replace sequences of hyphens with non-breaking spaces
        const hyphenReplacedText = withoutBracesText.replace(/---+/g, match => '&nbsp;'.repeat(match.length));

        // Regular expression to match text between asterisks
        const regex = /\*(.*?)\*/g;

        // Process text by replacing matched patterns with <strong> tags
        const processedText = hyphenReplacedText.replace(regex, (_, match) => (
            `<strong>${match}</strong>`
        ));

        // Split processed text by newlines and handle line breaks
        const parts = processedText.split(/[\n.]/).map((line, lineIndex) => (
            <React.Fragment key={lineIndex}>
                <span style={{ position: 'relative' }}>
                    <span dangerouslySetInnerHTML={{ __html: line }} />
                </span>
                <br />
            </React.Fragment>
        ));

        return parts;
    };

    return (

        <footer id='footer'>
            <div className="footer">
                <div className='mobile'>
                    <div style={{ width: '50%' }} id='footertow' className="column">
                        <h3>{t("Fcol3")}</h3>
                        <ul>
                            <li><Link to="/">{t("home")} </Link></li>
                            <div className='under-border'></div>
                            <li><Link to="/aboutus">{t("about")}</Link></li>
                            <div className='under-border'></div>
                            <li><Link to="/services">{t("servNav")}</Link></li>
                            <div className='under-border'></div>
                            <li><Link to="/offers">{t("OfferpgaeH")}</Link></li>
                            <div className='under-border'></div>
                            <li> <Link to="/newarrival">{t("newArriv")}</Link></li>
                            <div className='under-border'></div>
                        </ul>
                    </div>
                    <div style={{ width: '50%' }} id='footerone' className="column">
                        <h3>{t("Fcol2")}</h3>
                        <div className="bord phone">
                            <i className='phone'><BsFillPhoneFill /></i>
                            <div className='bord-cont'>
                                <p className='H'>{t("phoneH")}</p>
                                <p>{t("phonep")}</p>
                            </div>

                        </div>
                        <div className="bord mail">
                            <i className='mail'><MdEmail /></i>
                            <div className='bord-cont'>
                                <p>{t("mailH")}</p>
                                <p> <a href="mailto:ahmed.sayed@trendlineeg.com" target="_blank" rel="noopener noreferrer">ahmed.sayed@trendlineeg.com</a> </p>
                            </div>
                        </div>
                        <div className="bord address">
                            <i className='address'><FaMapMarkerAlt /></i>
                            <div className='bord-cont'>
                                <p>{t("addressH")}</p>
                                <p>{t("addressP")}</p>
                            </div>

                        </div>
                        <div className="bord time">
                            <i className='time'><TbClockHour4Filled /></i>
                            <div className='bord-cont'>
                                <p>{t("timeH")}</p>
                                <p>{t("timeP")}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='column' style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Link to="/aboutus" className=' cAbout'><h3>{t("Fcol1")}</h3>
                        <div className="bord aboute">
                            <p>{addBr(t("aboutP4"))}</p>
                        </div>
                    </Link>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={pay} alt="" style={{ position: "relative", textAlign: "center", maxWidth: "65%", background: "#fff", padding: "0.5rem", borderRadius: "10px" }} />
                    </div>
                </div>
            </div>
            <br />
            <div className='footer-end' dir='rtl'>
                <h3> <div className='bg-cover'> {t("copyR")} </div> <span> <a className='showLink' target="_blank" rel="noreferrer" href="https://missanvip.com/">https://missanvip.com</a> <a target="_blank" rel="noreferrer" href="https://missanvip.com/">{t("missan")}</a>  </span></h3>
                <h3 dir='ltr' >© 2019 - {currentYear} TrendLine Eg. All rights reserved</h3>
            </div>
        </footer >
    )
}

export default Footer