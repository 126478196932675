import { Link } from 'react-router-dom'
import { TbCategoryPlus } from "react-icons/tb";
import { BiCategory } from "react-icons/bi";
import { MdOutlinePlaylistAdd } from "react-icons/md";
import { MdOutlinePlaylistAddCheck } from "react-icons/md";



import './Sidebar.css'

const Sidebar = () => {
    return (
        <div className="sidebar">

            <Link to={'/adminpanal/addcategory'} style={{ textDecoration: "none" }}>
                <div className="sidebar-item">
                    <i> <TbCategoryPlus /> </i>
                    <p>Add Category</p>
                </div>
            </Link>

            <Link to={'/adminpanal/categorylist'} style={{ textDecoration: "none" }}>
                <div className="sidebar-item">
                    <i> <BiCategory /> </i>
                    <p>Category List</p>
                </div>
            </Link>

            <Link to={'/adminpanal/addproduct'} style={{ textDecoration: "none" }}>
                <div className="sidebar-item">
                    <i> <MdOutlinePlaylistAdd /> </i>
                    <p>Add Product</p>
                </div>
            </Link>

            <Link to={'/adminpanal/productlist'} style={{ textDecoration: "none" }}>
                <div className="sidebar-item">
                    <i> <MdOutlinePlaylistAddCheck /> </i>
                    <p>Product List</p>
                </div>
            </Link>

        </div>
    )
}

export default Sidebar