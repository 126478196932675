import React, { useEffect, useState } from 'react';
import '../newArrival/Newarrival.css';
import ItemCard from '../ItemCard/Item';
import i18next from 'i18next';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PropagateLoader } from "react-spinners";

const RelatedProducts = (props) => {
    const { t } = useTranslation();
    const [newProducts, setNewProducts] = useState([]);
    const [shuffledProducts, setShuffledProducts] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(5);
    const [loading, setLoading] = useState(true); // New state for loading
    const currentLanguage = i18next.language; // Get the current language from i18next.
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/categories/${props.Serv_name}/products?lang=${currentLanguage}`;

    useEffect(() => {
        fetchProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiUrl]);

    const fetchProducts = () => {
        setLoading(true); // Start loading
        axios.get(apiUrl)
            .then(response => {
                const products = response.data.products || response.data;
                if (Array.isArray(products)) {
                    setNewProducts(products);
                } else {
                    setNewProducts([]);
                }
                setLoading(false); // End loading
            })
            .catch(error => {
                console.error('Error fetching the products:', error);
                setLoading(false); // End loading on error
            });
    };

    useEffect(() => {
        if (newProducts.length > 0) {
            setShuffledProducts(shuffleArray(newProducts));
        } else {
            setShuffledProducts([]);
        }
    }, [newProducts]);

    // Shuffle array function
    const shuffleArray = (array) => {
        let shuffledArray = array.slice(); // Create a copy of the array
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };

    const handleResize = () => {
        const width = window.innerWidth;
        if (width <= 940) {
            setItemsToShow(4); // Show 4 items at 930px or less
        } else {
            setItemsToShow(5); // Show 5 items for wider screens
        }
    };

    useEffect(() => {
        // Set initial number of items based on current width
        handleResize();
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section id='newarrival' className='newarrival' dir={i18next.dir()}>
            <div className='containerr-' style={{ minWidth: "40%" }}>
                <div className='newarrival-header'>
                    <div><h4>{t("SBtnH")}</h4></div>
                    <div className='more-btn'>
                        <Link to={`/category/${props.gen_category}/${props.Serv_name}/products`}>{t("more-btn")}</Link>
                    </div>
                </div>

                <div className='pudact-container' id='prudactCards'>
                    {loading ? (
                        <p><PropagateLoader color="#3A5998" /></p>
                    ) : shuffledProducts.length > 0 ? (
                        shuffledProducts.slice(0, itemsToShow).map((item, i) => (
                            <div onClick={props.onClick} key={i}>
                                <ItemCard
                                    id={item.id}
                                    name_ar={currentLanguage === 'en' ? item.name_en : item.name_ar}
                                    img={item.imageProductCover}
                                    imgs={item.productImages.map(image => (image))} // Ensure images are correctly formatted
                                    brand_ar={currentLanguage === 'en' ? item.brand_en : item.brand_ar}
                                    modle_ar={currentLanguage === 'en' ? item.model_en : item.model_ar}
                                    description={currentLanguage === 'en' ? item.description_en : item.description_ar}
                                    isOffer={item.isOffer}
                                    rating={item.rating}
                                    discount={item.discount}
                                    isNew={item.isNew}
                                />
                            </div>
                        ))
                    ) : (
                        <p>{t("comingSoon")}</p>
                    )}
                </div>
            </div>
        </section>
    );
}

export default RelatedProducts;
