import React, { useEffect } from 'react'
import './AboutPage.css'
import { Helmet } from 'react-helmet'
import aboutimg from '../../Assets/slider1.jpg'
import { useTranslation } from 'react-i18next';
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import showCase from "../../Assets/showCase.jpg"
import cookies from 'js-cookie';
import i18n from 'i18next';


const AboutPage = () => {
    const lng = cookies.get('i18next') || '';

    useEffect(() => {

        const contactElement4 = document.querySelector('#aboutpage');
        if (contactElement4) {
            const direction = i18n.dir();
            contactElement4.dir = direction;
        }
    }, [lng]);

    const { t } = useTranslation();

    useEffect(() => {
        // Scroll to the top of the page whenever currentPage changes
        window.scrollTo(0, 0);
    }, []);

    const addBr = (text) => {
        if (!text) return '';

        // Remove text between double braces {{ }}
        const withoutBracesText = text.replace(/\{\{.*?\}\}/gs, '');

        // Replace sequences of hyphens with non-breaking spaces
        const hyphenReplacedText = withoutBracesText.replace(/---+/g, match => '&nbsp;'.repeat(match.length));

        // Regular expression to match text between asterisks
        const regex = /\*(.*?)\*/g;

        // Process text by replacing matched patterns with <strong> tags
        const processedText = hyphenReplacedText.replace(regex, (_, match) => (
            `<strong>${match}</strong>`
        ));

        // Split processed text by newlines and handle line breaks
        const parts = processedText.split(/[\n.]/).map((line, lineIndex) => (
            <React.Fragment key={lineIndex}>
                <span style={{ position: 'relative' }}>
                    <span dangerouslySetInnerHTML={{ __html: line }} />
                </span>
                <br />
            </React.Fragment>
        ));

        return parts;
    };
    return (
        <div>
            <Helmet>
                <title> {t("about")} </title>
                <link rel="canonical" href="https://trendlineeg.com/" />
                <meta property="og:image" content={showCase} />
                <meta property="og:title" content="TrendLine" />
                <meta property="og:url" content="https://trendlineeg.com" />
                <meta property="og:description" lang="ar"
                    content="شركة ترندلاين متخصصة في تقديم حلول منخفضة التيار المتكاملة بما في ذلك أنظمة المراقبة بالفيديو عالية الدقة، وأنظمة التحكم في الوصول، وأنظمة الإنذار بالحريق، وأنظمة الأمان، والخوادم ووحدات التخزين الشبكية، وجدران الحماية، واللاب توب وأجهزة الكمبيوتر، وقطع الكمبيوتر، والشاشات والتلفزيونات، والشاشات التفاعلية، والألياف البصرية وUTP، وحلول WiMax وUbiquiti. تقدم الشركة خدمات البيع المباشر، التوصيل، التركيب، والبرمجة لعملاء الإلكترونيات، اللاب توب، السيرفرات، الكمبيوترات التجميع، السمارت هوم، جدران الحماية، أنظمة المراقبة، إدارة المحال التجارية، إدارة الفنادق، التوصيل الشبكي بالكابل، الواي فاي، الفايبر أوبتيكال، خدمة العملاء، السنترال الداخلي، ربط الفروع، الفيديو كول، الإنتركوم المرئي والصوتي، الساوند المركزي، الدش والستالايت، وأنظمة الألواح الشمسية" />
                <meta property="og:description" lang="en"
                    content="Trendline specializes in providing integrated low current solutions, including HD video surveillance, access control systems, fire alarm systems, security systems, servers and NAS storage, firewalls, laptops and PCs, PC hardware, monitors and TVs, interactive screens, fiber optics and UTP, and WiMax and Ubiquiti solutions. The company offers direct sales, delivery, installation, and programming services for electronics, laptops, servers, custom-built computers, smart home, firewalls, surveillance systems, retail management, hotel management, wired, Wi-Fi, and fiber optic network solutions, customer service systems, internal PBX, branch connectivity, video call systems, intercom (video and audio), central sound systems, satellite dishes, and solar panels." />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:description" lang="ar"
                    content="شركة ترندلاين متخصصة في تقديم حلول منخفضة التيار المتكاملة بما في ذلك أنظمة المراقبة بالفيديو عالية الدقة، وأنظمة التحكم في الوصول، وأنظمة الإنذار بالحريق، وأنظمة الأمان، والخوادم ووحدات التخزين الشبكية، وجدران الحماية، واللاب توب وأجهزة الكمبيوتر، وقطع الكمبيوتر، والشاشات والتلفزيونات، والشاشات التفاعلية، والألياف البصرية وUTP، وحلول WiMax وUbiquiti. تقدم الشركة خدمات البيع المباشر، التوصيل، التركيب، والبرمجة لعملاء الإلكترونيات، اللاب توب، السيرفرات، الكمبيوترات التجميع، السمارت هوم، جدران الحماية، أنظمة المراقبة، إدارة المحال التجارية، إدارة الفنادق، التوصيل الشبكي بالكابل، الواي فاي، الفايبر أوبتيكال، خدمة العملاء، السنترال الداخلي، ربط الفروع، الفيديو كول، الإنتركوم المرئي والصوتي، الساوند المركزي، الدش والستالايت، وأنظمة الألواح الشمسية" />
                <meta name="twitter:description" lang="en"
                    content="Trendline specializes in providing integrated low current solutions, including HD video surveillance, access control systems, fire alarm systems, security systems, servers and NAS storage, firewalls, laptops and PCs, PC hardware, monitors and TVs, interactive screens, fiber optics and UTP, and WiMax and Ubiquiti solutions. The company offers direct sales, delivery, installation, and programming services for electronics, laptops, servers, custom-built computers, smart home, firewalls, surveillance systems, retail management, hotel management, wired, Wi-Fi, and fiber optic network solutions, customer service systems, internal PBX, branch connectivity, video call systems, intercom (video and audio), central sound systems, satellite dishes, and solar panels." />
                <meta name="twitter:image" content="URL_TO_IMAGE" />
                <meta name="twitter:title" content="TrendLine" />
            </Helmet>

            <div id='aboutpage' className='d-flex flex-column justify-content-center align-items-center'>
                <div className='serv-page-header d-flex flex-column justify-content-center align-items-center'>
                    <h1>{ }</h1>
                    <img src={aboutimg} alt="img" />
                    <h2>{t("about")}</h2>
                </div>
                <br />
                <div className=' serv-dics container d-flex flex-column justify-content-center'>
                    <h5> {t("aboutwlc")} </h5>
                    <br />
                    <p> <strong> {t("aboutH1")} </strong> </p>
                    <p> {t("aboutP1")} </p>

                    <p> <strong> {t("aboutH2")} </strong> </p>
                    <p> {addBr(t("aboutP2"))} </p>

                    <p> <strong> {t("aboutH3")} </strong> </p>
                    <p> {addBr(t("aboutP3"))} </p>

                    <p> <strong> {t("aboutH4")} </strong> </p>
                    <p> {addBr(t("aboutP4"))} </p>
                    <br />

                </div>
                <br />
                <div className=' serv-dics container d-flex flex-column justify-content-center'>

                    <p> <strong> {t("aboutH5")} </strong> </p>
                    <p> {addBr(t("aboutP5"))} </p>

                    <p> <strong> {t("aboutH6")} </strong> </p>
                    <p> {addBr(t("aboutP6"))} </p>

                    <p> <strong> {t("aboutH7")} </strong> </p>
                    <p> {addBr(t("aboutP7"))} </p>

                    <p> <strong> {t("aboutH8")} </strong> </p>
                    <p> {addBr(t("aboutP8"))} </p>
                    <br />

                </div>
                <div className=' serv-dics container d-flex flex-column justify-content-center'>
                    <div className='p-4 about-contact d-flex flex-column justify-content-center '>
                        <div className='d-flex align-items-center gap-2'>
                            <h5> <strong> {t("aboutcontactH")} </strong> {t("aboutcontactP1")}</h5>
                        </div>
                        <div className='p-2 about-contact-content'>
                            <div>
                                <p className='p-2'> <strong> {t("phone")} : </strong> +0201281717577 </p>
                                <p className='p-2'> <strong> {t("email")} : </strong> <a href="mailto:ahmed.sayed@trendlineeg.com"> ahmed.sayed@trendlineeg.com </a></p>
                            </div>
                            <div>
                                <p className='p-2'> <strong> {t("address")} :</strong> {t("about-address")} </p>
                                <p className='p-2'> <strong> {t("website")} : </strong> <a href="https://trendlineeg.com">https://trendlineeg.com</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' serv-dics container d-flex flex-column justify-content-center'>
                    <div className='p-2 about-contact d-flex flex-column justify-content-center '>
                        <div className='d-flex align-items-center gap-1'>
                            <h5> <strong> {t("follow")} : </strong> {t("aboutcontactP2")}</h5>

                        </div>
                        <br />
                        <div className='about-contact-links'>
                            <div className='about-links'>
                                <div>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.facebook.com/Trendlineeg/"> <FaFacebookF /> facebook</a>
                                </div>
                                <div>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.instagram.com/trendlineeg/"> <FaInstagram /> Instagram</a>
                                </div>
                                <div>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://x.com/Trendlineeg1"> <FaXTwitter /> Twitter</a>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div>
                            <h5>{t("aboutending")}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutPage