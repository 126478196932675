import React from 'react';
import './CategoryItem.css';
import { Link } from 'react-router-dom';

const CategoryItem = ({ gen_category, category_name, img }) => {
    return (

        <div>
            <div className='catcontainer'>
                <Link to={`/category/${gen_category}/${category_name}/products`}>
                    <div className='catygoryitem'>
                        <div className='cateImgs-container'>
                            <img src={img} alt='img' />
                        </div>
                        <h2 className='serv-content'>{category_name}</h2>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default CategoryItem;
