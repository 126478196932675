// Search.js
import React, { useState, useEffect, useRef, forwardRef } from 'react';
import './Search.css';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

const Search = forwardRef((props, ref) => {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showResults, setShowResults] = useState(false);
    const currentLanguage = i18next.language;
    const resultsRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (resultsRef.current && !resultsRef.current.contains(event.target)) {
                setShowResults(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/general/search?name=${searchTerm}&lang=${currentLanguage} ` || `${process.env.REACT_APP_BASE_URL}/api/v1/general/search?band=${searchTerm}&lang=${currentLanguage}`);
            const result = await response.json();
            if (result.products && Array.isArray(result.products)) {
                setData(result.products);
                setShowResults(true); // Show results when data is fetched
            } else {
                setData([]);
                setShowResults(false); // Hide results if no data
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setData([]);
            setShowResults(false); // Hide results on error
        }
    };

    useEffect(() => {
        if (searchTerm) {
            fetchData();
        } else {
            setData([]);
            setShowResults(false); // Hide results when search term is empty
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, currentLanguage]);

    const handleChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleInputClick = () => {
        if (searchTerm) {
            setShowResults(true); // Show results when input is clicked and there's a search term
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission on Enter key press
            window.location.href = `/product/search/${searchTerm}`; // Navigate to the search link
        }
    };

    return (
        <div className="search" dir={i18next.dir()}>
            <div className="search-card">
                <form className="custom_input" action="search">
                    {searchTerm ? null : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="svg_iconR bi-search"
                            viewBox="0 0 16 16"
                        >
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                        </svg>
                    )}
                    <Link to={`/product/search/${searchTerm}`} className={searchTerm ? ' searchIcon' : 'iconHide'}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="svg_icon bi-search"
                            viewBox="0 0 16 16"
                        >
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                        </svg>
                    </Link>
                    <input
                        ref={ref}
                        className="input"
                        onClick={handleInputClick}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        type="text"
                        placeholder={t("search")}
                        value={searchTerm}
                        required
                    />
                </form>
                <div className='result-container'>
                    <div className="result-card" ref={resultsRef} style={{ display: showResults && data.length > 0 ? 'grid' : 'none' }} dir={i18next.dir()}>
                        {Array.isArray(data) && data.length > 0 ? (
                            data.map((item) => (
                                <div className="result" key={item.id}>
                                    <a href={`/product/${item.gen_category}/${item.category_name}/product/${item.id}`}>
                                        <div>
                                            <img
                                                style={{ width: '20%' }}
                                                src={item.imageProductCover}
                                                alt={currentLanguage === 'en' ? item.name_en : item.name_ar}
                                            />
                                            <div className='ResContent'>
                                                <p>{currentLanguage === 'en' ? item.brand_en : item.brand_ar}</p>
                                                <p>{currentLanguage === 'en' ? item.name_en : item.name_ar}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))
                        ) : (
                            searchTerm && <p className='p-2' style={{ textAlign: 'center' }}>No results found</p>
                        )}
                        {data.length > 0 && (
                            <button>
                                <Link to={`/product/search/${searchTerm}`}> View All Results</Link>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Search;
