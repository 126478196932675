import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import ServicesCards from '../localData/ServicesData.js';
import { Helmet } from 'react-helmet';
import cookies from 'js-cookie';
import i18n from 'i18next';
import { GridLoader } from "react-spinners";
import RelatedProducts from '../Components/RelatedProduts/Related.jsx';
import showCase from "../Assets/showCase.jpg"
import { useTranslation } from 'react-i18next';

function ServiceDetail() {
    const { t } = useTranslation();
    const { gen_category, Serv_name, id } = useParams();
    const servicesData = ServicesCards();
    const [service, setService] = useState(null);
    const lng = cookies.get('i18next') || '';

    useEffect(() => {
        const fetchedService = servicesData.find(s => s.id === parseInt(id, 10));
        setService(fetchedService);
    }, [id, servicesData]);

    useEffect(() => {
        const servDetail = document.querySelector('#servDetail');
        if (servDetail) {
            servDetail.dir = i18n.dir();
        }
    }, [lng]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (!service) {
        return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: "0", left: "0", width: "100%", height: "100%", zIndex: "3", backgroundColor: "#fff" }}>
            <GridLoader color="#3A5998" />
        </div>;
    }

    const addCommas = (text) => {
        return text ? text.split(' ').join(', ') : '';
    };

    const addBr = (text) => {
        if (!text) return '';

        // Remove text between double braces {{ }}
        const withoutBracesText = text.replace(/\{\{.*?\}\}/gs, '');

        // Replace sequences of hyphens with non-breaking spaces
        const hyphenReplacedText = withoutBracesText.replace(/---+/g, match => '&nbsp;'.repeat(match.length));

        // Regular expression to match text between asterisks
        const regex = /\*(.*?)\*/g;

        // Process text by replacing matched patterns with <strong> tags
        const processedText = hyphenReplacedText.replace(regex, (_, match) => (
            `<strong>${match}</strong>`
        ));

        // Split processed text by newlines and handle line breaks
        const parts = processedText.split('\n').map((line, lineIndex) => (
            <React.Fragment key={lineIndex}>
                <span style={{ position: 'relative' }}>
                    <span dangerouslySetInnerHTML={{ __html: line }} />
                </span>
                <br />
            </React.Fragment>
        ));

        return parts;
    };

    return (
        <div>
            <Helmet>
                <title>{service.Serv_name}</title>
                <link rel="canonical" href="https://trendlineeg.com/" />
                <meta name="description" content={addCommas(service?.description)} />
                <meta name="keywords" content={addCommas(service?.Serv_name)} />
                <meta property="og:image" content={showCase} />
                <meta property="og:title" content={`TrendLine ${service.Serv_name}`} />
                <meta property="og:url" content="https://trendlineeg.com" />
                <meta property="og:description" lang="ar"
                    content="شركة ترندلاين متخصصة في تقديم حلول منخفضة التيار المتكاملة بما في ذلك أنظمة المراقبة بالفيديو عالية الدقة، وأنظمة التحكم في الوصول، وأنظمة الإنذار بالحريق، وأنظمة الأمان، والخوادم ووحدات التخزين الشبكية، وجدران الحماية، واللاب توب وأجهزة الكمبيوتر، وقطع الكمبيوتر، والشاشات والتلفزيونات، والشاشات التفاعلية، والألياف البصرية وUTP، وحلول WiMax وUbiquiti. تقدم الشركة خدمات البيع المباشر، التوصيل، التركيب، والبرمجة لعملاء الإلكترونيات، اللاب توب، السيرفرات، الكمبيوترات التجميع، السمارت هوم، جدران الحماية، أنظمة المراقبة، إدارة المحال التجارية، إدارة الفنادق، التوصيل الشبكي بالكابل، الواي فاي، الفايبر أوبتيكال، خدمة العملاء، السنترال الداخلي، ربط الفروع، الفيديو كول، الإنتركوم المرئي والصوتي، الساوند المركزي، الدش والستالايت، وأنظمة الألواح الشمسية" />
                <meta property="og:description" lang="en"
                    content="Trendline specializes in providing integrated low current solutions, including HD video surveillance, access control systems, fire alarm systems, security systems, servers and NAS storage, firewalls, laptops and PCs, PC hardware, monitors and TVs, interactive screens, fiber optics and UTP, and WiMax and Ubiquiti solutions. The company offers direct sales, delivery, installation, and programming services for electronics, laptops, servers, custom-built computers, smart home, firewalls, surveillance systems, retail management, hotel management, wired, Wi-Fi, and fiber optic network solutions, customer service systems, internal PBX, branch connectivity, video call systems, intercom (video and audio), central sound systems, satellite dishes, and solar panels." />
            </Helmet>

            <div id='servDetail' dir={i18n.dir()} className='d-flex flex-column justify-content-center align-items-center'>
                <div className='serv-page-header d-flex flex-column justify-content-center align-items-center'>
                    <h1>{service.name}</h1>
                    <img src={service.img} alt={service.Serv_name} />
                    <h2>{service.Serv_name}</h2>
                </div>
                <br />
                <div className='serv-dics container d-flex flex-column align-items-center justify-content-center'>
                    <LinkS className='servToPrud' to="servProducts">{t("SBtnHS")}</LinkS>
                    <div className='container d-flex flex-column justify-content-center'>
                        <h5>{service.desH1}</h5>
                        <p>{addBr(service.desP1)}</p>
                        <br />
                        <h5>{service.desH2}</h5>
                        <p>{addBr(service.desP2)}</p>
                        <br />
                        <h5>{service.desH3}</h5>
                        <p>{addBr(service.desP3)}</p>
                        <br />
                        <h5>{service.desH4}</h5>
                        <p>{addBr(service.desP4)}</p>
                        <br />
                        <h5>{service.desH5}</h5>
                        <p>{addBr(service.desP5)}</p>
                        <br />
                        <h5>{service.desH6}</h5>
                        <p>{addBr(service.desP6)}</p>
                        <br />
                        <h5>{service.desH7}</h5>
                        <p>{addBr(service.desP7)}</p>
                        <br />
                        <h5>{service.desH8}</h5>
                        <p>{addBr(service.desP8)}</p>
                        <br />
                        <h5>{service.desH9}</h5>
                        <p>{addBr(service.desP9)}</p>
                    </div>
                </div>
                <div id='servProducts' style={{ width: "100%", margin: "1rem 0" }}>
                    <RelatedProducts Serv_name={Serv_name} gen_category={gen_category} />
                </div>

            </div>
        </div>
    );
}

export default ServiceDetail;
