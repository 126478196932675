import React from 'react'
import Navbar from '../Components/AdminComponents/Navbar/Navbar'
import Admin from '../Pages/Admin/Admin'


const AdminPanlePage = () => {
    return (
        <div>
            <Navbar />
            <Admin />
        </div>
    )
}

export default AdminPanlePage