import './ListProduct.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pagination from 'react-bootstrap/Pagination';
import { MdEdit, MdDeleteForever } from "react-icons/md";

const ListProduct = () => {
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [productDetails, setProductDetails] = useState({
        productName_en: "",
        productName_ar: "",
        productBrand_ar: "",
        productBrand_en: "",
        productModel_ar: "",
        productModel_en: "",
        productDescription_ar: "",
        productDescription_en: "",
        productRating: "",
        productReviews: "",
        productIsNew: false,
        productIsOffer: false,
        productDiscount: ""
    });
    const [showEdit, setShowEdit] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const itemsPerPage = 4;
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const token = sessionStorage.getItem('token');
    const [error, setError] = useState('');

    useEffect(() => {
        if (!token) {
            console.error('No token found. Please login.');
            setError('No token found. Please login.');
            return;
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/categories/sub-categories?lang=ar`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the categories!', error);
                setError('There was an error fetching the categories.');
            });
    }, [token]);

    useEffect(() => {
        if (selectedCategory) {
            fetchProducts();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCategory, token]);

    const fetchProducts = async () => {
        if (!selectedCategory || !token) return;

        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/categories/${selectedCategory}/products?lang=en`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data && Array.isArray(response.data.products)) {
                setProducts(response.data.products);
            } else {
                console.error('Expected an array but got:', response.data);
                setProducts([]);
            }
        } catch (error) {
            console.error('Error fetching products:', error);
            setError('Error fetching products.');
        }
    };

    const deleteProduct = (product) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete the product ${product.name_en}?`);
        if (isConfirmed && token) {
            axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/products/delete/${product.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        setProducts(products.filter(p => p.id !== product.id));
                    } else {
                        console.error('Error deleting product:', response);
                    }
                })
                .catch(error => {
                    console.error('Error deleting product:', error);
                });
        }
    };

    const handleEditClick = (product) => {
        setSelectedProductId(product.id);
        setProductDetails({
            productName_en: product.name_en,
            productName_ar: product.name_ar,
            productBrand_ar: product.brand_ar,
            productBrand_en: product.brand_en,
            productModel_ar: product.model_ar,
            productModel_en: product.model_en,
            productDescription_ar: product.description_ar,
            productDescription_en: product.description_en,
            productRating: product.rating,
            productReviews: product.reviews,
            productIsNew: product.isNew,
            productIsOffer: product.isOffer,
            productDiscount: product.discount
        });
        setShowEdit(true);
    };

    const closeEdit = () => {
        setShowEdit(false);
        setSelectedProductId(null);
        setProductDetails({
            productName_en: "",
            productName_ar: "",
            productBrand_ar: "",
            productBrand_en: "",
            productModel_ar: "",
            productModel_en: "",
            productDescription_ar: "",
            productDescription_en: "",
            productRating: "",
            productReviews: "",
            productIsNew: false,
            productIsOffer: false,
            productDiscount: ""
        });
    };

    const changeHandler = (e) => {
        const { name, value, type, checked } = e.target;
        setProductDetails(prevDetails => ({
            ...prevDetails,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const modifyProduct = async (e) => {
        e.preventDefault();

        if (!selectedProductId) return;

        const data = {
            name_en: productDetails.productName_en,
            name_ar: productDetails.productName_ar,
            brand_ar: productDetails.productBrand_ar,
            brand_en: productDetails.productBrand_en,
            model_ar: productDetails.productModel_ar,
            model_en: productDetails.productModel_en,
            description_ar: productDetails.productDescription_ar,
            description_en: productDetails.productDescription_en,
            rating: productDetails.productRating,
            reviews: productDetails.productReviews,
            isNew: productDetails.productIsNew,
            isOffer: productDetails.productIsOffer,
            discount: productDetails.productDiscount
        };


        try {
            const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/products/product/modify/${selectedProductId}`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                await fetchProducts(); // Re-fetch the products
                closeEdit(); // Close modal
            } else {
                console.error('Error modifying product:', response);
                setError('Error modifying product.');
            }
        } catch (error) {
            console.error('Error modifying product:', error);
            setError('Error modifying product.');
        }
    };


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage]);

    const renderPagination = () => {
        if (totalPages <= 1) return null;

        const paginationItems = [];

        // First page
        paginationItems.push(
            <Pagination.Item key={1} active={currentPage === 1} onClick={() => handlePaginationClick(1)}>
                1
            </Pagination.Item>
        );

        // Ellipsis before current page if needed
        if (currentPage > 2) {
            paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }

        // Current page
        if (currentPage > 1 && currentPage <= totalPages - 1) {
            paginationItems.push(
                <Pagination.Item key={currentPage} active onClick={() => handlePaginationClick(currentPage)}>
                    {currentPage}
                </Pagination.Item>
            );
        }

        // Ellipsis after current page if needed
        if (currentPage < totalPages - 1) {
            paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
        }

        // Last page
        if (totalPages > 1) {
            paginationItems.push(
                <Pagination.Item key={totalPages} active={currentPage === totalPages} onClick={() => handlePaginationClick(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return paginationItems;
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = products.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate page numbers
    const totalPages = Math.ceil(products.length / itemsPerPage);

    const handlePaginationClick = (page) => {
        setCurrentPage(page);
    };


    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setCurrentPage(1); // Reset to first page when category changes
    };

    return (
        <div className='productsT'>
            <div className="addproduct-itemfield">
                <div className="product-category">
                    <select onChange={handleCategoryChange} value={selectedCategory}>
                        <option value="">select category - اختر الفئة</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.name}>{category.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            {error && <p className="text-danger">{error}</p>}
            <table>
                <thead>
                    <tr>
                        <th className='Pimg'>صورة المنتج</th>
                        <th className='name'>اسم المنتج</th>
                        <th>الماركة</th>
                        <th>الصنف</th>
                        <th>جديد</th>
                        <th>عرض</th>
                        <th>خصم</th>
                        <th>تعديل</th>
                        <th>حذف</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((product, index) => (
                        <tr key={index}>
                            <td className='Pimg'> <img src={product.imageProductCover} alt="ProductImage" /> </td>
                            <td className='name'> <p>{product.name_en} </p> </td>
                            <td>{product.brand_en}</td>
                            <td>{product.model_en}</td>
                            <td>{product.isNew ? 'Yes' : 'No'}</td>
                            <td>{product.isOffer ? 'Yes' : 'No'}</td>
                            <td>{product.discount}</td>
                            <td>
                                <button className='edit' onClick={() => handleEditClick(product)}>
                                    <MdEdit />
                                </button>
                            </td>
                            <td>
                                <button className='delete' onClick={() => deleteProduct(product)}>
                                    <MdDeleteForever style={{ color: "red" }} />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='PagiNation'>
                {totalPages > 1 && (
                    <Pagination>
                        <Pagination.First onClick={() => handlePaginationClick(currentPage - 1)} disabled={currentPage === 1} />
                        {renderPagination()}
                        <Pagination.Last onClick={() => handlePaginationClick(currentPage + 1)} disabled={currentPage === totalPages} />
                    </Pagination>
                )}
            </div>

            {showEdit && (
                <div className="modal-overlay" onClick={closeEdit}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className='model-head'>
                            <div>
                                <h5>Edit product </h5>
                            </div>
                            <div>
                                <span className="close-btn" onClick={closeEdit}>&times;</span>
                            </div>
                        </div>
                        <div className="add-product" style={{ overflow: "scroll" }}>
                            <div className="admin-page-container">

                                {error && <p className="text-danger">{error}</p>}

                                <form>
                                    <div className="addproduct-itemfield">
                                        <div className="product-en">
                                            <p>Product Name</p>
                                            <input type="text" name="productName_en" value={productDetails.productName_en} onChange={changeHandler} placeholder="Name (EN)" />
                                        </div>
                                        <div className="product-ar" dir='rtl'>
                                            <p>أسم المنتج</p>
                                            <input type="text" name="productName_ar" value={productDetails.productName_ar} onChange={changeHandler} placeholder="Name (AR)" />
                                        </div>
                                    </div>

                                    <div className="addproduct-brand">
                                        <div className="addproduct-itemfield">
                                            <div className="product-en">
                                                <p>Brand Name</p>
                                                <input type="text" name="productBrand_en" value={productDetails.productBrand_en} onChange={changeHandler} placeholder="Brand (EN)" />
                                            </div>
                                            <div className="product-ar" dir='rtl'>
                                                <p>أسم الماركة</p>
                                                <input type="text" name="productBrand_ar" value={productDetails.productBrand_ar} onChange={changeHandler} placeholder="Brand (AR)" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="addproduct-model">
                                        <div className="addproduct-itemfield">
                                            <div className="product-en">
                                                <p>Model Name</p>
                                                <input type="text" name="productModel_en" value={productDetails.productModel_en} onChange={changeHandler} placeholder="Model (EN)" />
                                            </div>
                                            <div className="product-ar" dir='rtl'>
                                                <p>أسم الصنف</p>
                                                <input type="text" name="productModel_ar" value={productDetails.productModel_ar} onChange={changeHandler} placeholder="Model (AR)" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="addproduct-dic">
                                        <div className="addproduct-itemfield">
                                            <div>
                                                <p>Description</p>
                                                <textarea type="text" name="productDescription_en" value={productDetails.productDescription_en} onChange={changeHandler} placeholder="Description (EN)" />
                                            </div>
                                            <div className="product-ar" dir='rtl'>
                                                <p>الوصف</p>
                                                <textarea type="text" name="productDescription_ar" value={productDetails.productDescription_ar} onChange={changeHandler} placeholder="Description (AR)" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="addproduct-dic">
                                        <div className="addproduct-itemfield">
                                            <div>
                                                <p>Rating - التقيم</p>
                                                <input type="number" name="productRating" value={productDetails.productRating} onChange={changeHandler} placeholder="Rating - التقيم" step={0.5} min={0} max={5} />
                                            </div>
                                            <div>
                                                <p>Reviews - المراجعات</p>
                                                <input type="number" name="productReviews" value={productDetails.productReviews} onChange={changeHandler} placeholder="Reviews - المراجعات" />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="addproduct-itemfieldd">
                                        <div className="product-en">
                                            <p>discount - خصم</p>
                                            <input type="number" name="productDiscount" value={productDetails.productDiscount} onChange={changeHandler} placeholder="20%" />
                                        </div>

                                        <div className="product-en">
                                            <p>انتهاء العرض</p>
                                            <input type="date" name="offerExpiresAt" value={productDetails.productOfferExpiresAt} onChange={changeHandler} />

                                        </div>
                                        <div className='chick-boxs'>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="productIsNew"
                                                    checked={productDetails.productIsNew}
                                                    onChange={changeHandler}
                                                />
                                                Is New - جديد
                                            </label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    name="productIsOffer"
                                                    checked={productDetails.productIsOffer}
                                                    onChange={changeHandler}
                                                />
                                                Is Offer - عرض
                                            </label>
                                        </div>

                                    </div>

                                    {/* <input type="file" name="imageProductCover" onChange={handleFileChange} /> */}

                                    <button onClick={modifyProduct} className='addproduct-btn'>Edit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default ListProduct;
