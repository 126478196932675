import React, { useState } from 'react'
import { IoIosEye } from "react-icons/io";
import StarsRating from '../StarsRating/StarsRating';
import i18n from 'i18next';
import './Item.css'
import { GoDotFill } from "react-icons/go";
import { Link } from 'react-router-dom';
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from 'react-i18next';

const ItemCard = (props) => {
    const { t } = useTranslation();
    const [showPop, setShowPop] = useState(false);
    const [showMsg, setShowMsg] = useState(false);
    const [urName, setUrName] = useState('');
    const [urPhone, setUrPhone] = useState('');
    const [urCity, setUrCity] = useState('');
    const [urAddress, setUrAddress] = useState('');
    const [formValid, setFormValid] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null);
    const [hoveredImage, setHoveredImage] = useState(props.img); // State to keep track of hovered image

    const handleClick = () => {
        setShowPop(!showPop);
    };
    const handleMsgClick = () => {
        setShowMsg(!showMsg);
    };

    const handleSelectedItem = (item) => {
        setSelectedItem(item);
    };

    const handleImageHover = (img) => {
        setHoveredImage(img);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name') setUrName(value);
        if (name === 'city') setUrCity(value);
        if (name === 'phone') setUrPhone(value);
        if (name === 'address') setUrAddress(value);
    };

    const constructWhatsAppLink = () => {
        const baseUrl = 'https://api.whatsapp.com/send';
        const phone = '201281717577'; // Replace with your WhatsApp number
        const message = `${t("ordermsg")} \n${t("Ordproduct")} ${props.name_ar} \n${("Ordname")} ${urName}\n${t("Ordphone")} ${urPhone}\n${t("Ordcity")} ${urCity}\n${t("Ordaddress")} ${urAddress}`;
        return `${baseUrl}?phone=${phone}&text=${encodeURIComponent(message)}`;
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (urName && urPhone && urCity && urAddress) {
            // All fields are filled, allow submission
            window.open(constructWhatsAppLink(), '_blank', 'noreferrer');
        } else {
            // Show an error message or handle the case where not all fields are filled
            setFormValid(false);
        }
    };


    const addBr = (text) => {
        if (!text) return '';

        // Remove text between double braces {{ }}
        const withoutBracesText = text.replace(/\{\{.*?\}\}/gs, '');

        // Replace sequences of hyphens with non-breaking spaces
        const hyphenReplacedText = withoutBracesText.replace(/---+/g, match => '&nbsp;'.repeat(match.length));

        // Regular expression to match text between asterisks
        const regex = /\*(.*?)\*/g;

        // Process text by replacing matched patterns with <strong> tags
        const processedText = hyphenReplacedText.replace(regex, (_, match) => (
            `<strong>${match}</strong>`
        ));

        // Split processed text by newlines and handle line breaks
        const parts = processedText.split('\n').map((line, lineIndex) => (
            <React.Fragment key={lineIndex}>
                <span style={{ position: 'relative' }}>
                    <GoDotFill style={{ fontSize: "0.6rem", margin: "0.5rem 0.1rem", padding: "0" }} />
                    <span dangerouslySetInnerHTML={{ __html: line }} />
                </span>
                <br />
            </React.Fragment>
        ));

        return parts;
    };



    return (
        <div className='prodcard col' dir={i18n.dir()}>
            {showPop ? (
                <div className='popup' onClick={handleClick}>
                    <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                        <div className="title-section">
                            <button className='close-btn' onClick={handleClick}>&times;</button>
                            <p className="title">{t("Qview")} </p>
                        </div>
                        <div className="popup-item">
                            <div className='Pimgs-container'>
                                <div className='popup-product-imgs'>
                                    {props.imgs && props.imgs.length > 0 ? (
                                        props.imgs.map((img, index) => (
                                            <div key={index}
                                                className='popup-product-img'
                                                onMouseEnter={() => handleImageHover(img)} // Update hovered image on hover
                                                onMouseLeave={() => handleImageHover(props.img)} // Revert to original image on mouse leave
                                            >
                                                <img
                                                    src={img}
                                                    alt={`img`}
                                                />
                                            </div>
                                        ))
                                    ) : (
                                        <p></p>
                                    )}
                                </div>
                                <img className='coverImg' src={hoveredImage} alt="" />
                            </div>
                            <div className='popup-text'>
                                <div className='d-flex p-1' style={{ background: "#3a5998", borderRadius: "5px", color: "#fff", marginBottom: "0.5rem" }}>
                                    {props.isNew ? <p className='product-labol-new'> {t("new")} </p> : null}
                                    {props.isOffer && props.discount > 0 ? <p className='product-labol-discount' dir='ltr'>-{props.discount}%</p> : null}
                                </div>
                                <p className='popupName'>{selectedItem.name_ar}</p>
                                <div style={{ paddingBottom: "0.6rem" }}><StarsRating rating={props.rating} /></div>
                                <h6><strong>{selectedItem.brand_ar}</strong></h6>
                                <h6><strong>{selectedItem.modle_ar}</strong></h6>
                                <div className='popup-btn' style={{ color: "#fff" }}
                                    onClick={handleMsgClick}
                                >{t("ordernow")}</div>
                                <span className='popup-description'>
                                    <span><strong>{t("desc")}<IoIosArrowDown /></strong></span>
                                    <p>{addBr(props.description)}</p>
                                    <Link to={`/product/${props.gen_category}/${props.category_name}/product/${props.id}`}>{t("more-btn")}</Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            <div className='item'>
                <div>
                    <div className='iicon Icon-eye'
                        onClick={() => {
                            handleClick();
                            handleSelectedItem(props);
                        }}
                    >
                        <IoIosEye />
                    </div>
                    <div className='product-labol' dir='ltr'>
                        {props.isOffer && props.discount > 0 ? <p className='product-labol-discount'>-{props.discount}%</p> : null}
                        {props.isNew ? <p className='product-labol-new'> {t("new")} </p> : null}
                    </div>
                </div>
                <Link to={`/product/${props.gen_category}/${props.category_name}/product/${props.id}`} onClick={props.onClick}>
                    <div className='prodImg'>
                        <img src={hoveredImage} alt="" />
                        <div className='product-imgs'>
                            {props.imgs && props.imgs.length > 0 ? (
                                props.imgs.map((img, index) => (
                                    <div key={index}
                                        className='product-img'
                                        onMouseEnter={() => handleImageHover(img)} // Update hovered image on hover
                                        onMouseLeave={() => handleImageHover(props.img)} // Revert to original image on mouse leave
                                    >
                                        <img
                                            src={img}
                                            alt={`img`}
                                        />
                                    </div>
                                ))
                            ) : (
                                <p></p>
                            )}
                        </div>
                    </div>
                    <div className='prodContent'>
                        <div className='itemBrand' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}> <div>{props.brand_ar}</div>  <StarsRating rating={props.rating} /> </div>
                        <div className='itemModel'>{props.modle_ar}</div>
                        <div className='itemName'>{props.name_ar}</div>
                    </div>
                </Link>
                <button>
                    <div onClick={handleMsgClick} style={{ color: "#fff" }}>{t("ordernow")}</div>
                </button>
            </div>
            {showMsg ? (
                <div className='popup' style={{ minHeight: "70vh" }} onClick={handleMsgClick}>
                    <div className="popup-content" style={{ minHeight: "50vh", minWidth: "30vw", backgroundColor: "#DFE3EE" }} onClick={(e) => e.stopPropagation()}>
                        <div className="title-section">
                            <button className='close-btn' onClick={handleMsgClick}>&times;</button>
                            <p className="title">{t("ordernow")}</p>
                        </div>
                        <div className='order-content'>
                            <div className="popup-item">
                                <form id="orderForm" className='msgs-info' onSubmit={handleFormSubmit}>
                                    <div className='msgs-info-row'>
                                        <div className='msg-info'>
                                            <p>{t("Contname")}</p>
                                            <input type="text" style={{ width: "98%" }} placeholder={t("Contname")} name='name' value={urName} onChange={handleInputChange} required />
                                        </div>
                                        <div className='msg-info'>
                                            <p>{t("Contphone")}</p>
                                            <input type="tel" style={{ width: "98%" }} placeholder={t("Contphone")} name='phone' value={urPhone} onChange={handleInputChange} required />
                                        </div>
                                    </div>
                                    <div className='msg-info'>
                                        <p>{t("Contcity")}</p>
                                        <input type="text" name='city' placeholder={t("Contcity")} value={urCity} onChange={handleInputChange} required />
                                    </div>
                                    <div className='msg-info'>
                                        <p>{t("ContAddress")}</p>
                                        <input type="text" name='address' placeholder={t("ContAddress")} value={urAddress} onChange={handleInputChange} required />
                                    </div>
                                    <button type="submit" className='popup-btn' style={{ color: "#fff", width: "100%", marginTop: "1.5rem", border: "none" }}>
                                        {t("ordernow")}
                                    </button>
                                </form>
                                {!formValid && <p style={{ color: 'red', marginTop: '1rem' }}>Please fill out all fields before submitting.</p>}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

        </div>
    );
}

export default ItemCard;
