import React, { useState } from "react";
import { FaWhatsapp } from "react-icons/fa6";
import { IoIosArrowUp } from "react-icons/io";
import { Link as LinkS } from 'react-scroll'


function Icon() {

    const [iShow, setiShow] = useState(false)
    const ShowIcon = () => {
        if (window.scrollY >= 400) {
            setiShow(true)
        } else {
            setiShow(false)
        }
    }

    window.addEventListener('scroll', ShowIcon)

    return (
        <div className="sticky-i">
            <LinkS to="navbar"
                className={iShow ? ' S-btn ' : 'S-btn hidden'}>
                <IoIosArrowUp />
            </LinkS>

            <a

                className="S-btn"
                href="https://api.whatsapp.com/send?phone=201281717577"
                target="_blank"
                rel="noreferrer"
            >
                <FaWhatsapp />
            </a>
        </div>
    )
}

export default Icon