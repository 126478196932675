import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'


const NavDropData1 = () => {
    const { t } = useTranslation();
    const [navDropData1, setNavDropData1] = useState([]);


    useEffect(() => {
        setNavDropData1([
            {
                key: 1,
                nav_d1_category: t("nav_d1_category1"),
            },
            {
                key: 2,
                nav_d1_category: t("nav_d1_category2"),
            },
            {
                key: 3,
                nav_d1_category: t("nav_d1_category3"),
            },
            {
                key: 4,
                nav_d1_category: t("nav_d1_category4"),
            }

        ]);
    }, [t]);


    return navDropData1;

}

export default NavDropData1
