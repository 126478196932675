import './Admin.css';
import { Routes, Route } from 'react-router-dom';
import Sidebar from '../../Components/AdminComponents/Sidebar/Sidebar';
import AddProduct from '../../Components/AdminComponents/AddProduct/AddProduct';
import ListProduct from '../../Components/AdminComponents/ListProduct/ListProduct';
import AddCategory from '../../Components/AdminComponents/AddCategory/AddCategory';
import ListCategory from '../../Components/AdminComponents/ListCategory/ListCategory';

const Admin = () => {
    return (
        <div className="admin">
            <Sidebar />
            <div className='admin-container'>
                <Routes>
                    <Route path="addcategory" element={<AddCategory />} />
                    <Route path="categorylist" element={<ListCategory />} />
                    <Route path="addproduct" element={<AddProduct />} />
                    <Route path="productlist" element={<ListProduct />} />
                </Routes>
            </div>
        </div>
    );
};

export default Admin;
