import React, { useEffect, useRef, useState } from 'react';
import ItemCard from '../Components/ItemCard/Item';
import './ProductPage.css';
import axios from 'axios';
import Pagination from 'react-bootstrap/Pagination';
import { FaSortAmountDownAlt, FaSortAmountUp, FaSortAlphaUp } from "react-icons/fa";
import { CgSandClock } from "react-icons/cg";
import { RiDiscountPercentFill } from "react-icons/ri";
import { GridLoader } from "react-spinners";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { IoHome } from "react-icons/io5";
import { Link } from 'react-router-dom';
import showCase from "../Assets/showCase.jpg"
import { Helmet } from 'react-helmet';

function OffersPage() {
    const { t } = useTranslation();
    const [offerProducts, setOfferProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedSort, setSelectedSort] = useState('name');
    const [isAscending, setIsAscending] = useState(true);
    const [loading, setLoading] = useState(true); // New state for loading
    const [isReverseOrder, setIsReverseOrder] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const itemsPerPage = 16; // Number of items per page
    const currentLanguage = i18next.language; // Get the current language from i18next.
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/general/all-products?lang=${currentLanguage}`;

    useEffect(() => {
        fetchProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiUrl]); // Fetch products initially

    const fetchProducts = () => {
        setLoading(true); // Start loading
        axios.get(apiUrl)
            .then(response => {
                const products = response.data.products || response.data; // Adjust based on actual structure
                if (Array.isArray(products)) {
                    const offerProducts = products.filter(product => product.isOffer);
                    setOfferProducts(offerProducts);
                } else {
                    setOfferProducts([]);
                }
                setLoading(false); // End loading
            })
            .catch(error => {
                console.error('Error fetching the products:', error);
                setLoading(false); // End loading
            });
    };


    const filteredProducts = selectedBrand
        ? offerProducts.filter(product => product.brand_en === selectedBrand || product.brand_ar === selectedBrand)
        : offerProducts;

    const sortedProducts = filteredProducts.sort((a, b) => {
        let comparison = 0;
        const locale = currentLanguage === 'ar' ? 'ar' : 'en';

        switch (selectedSort) {
            case 'name':
                const nameA = a.name_en || a.name_ar || '';
                const nameB = b.name_en || b.name_ar || '';
                comparison = nameA.localeCompare(nameB, locale, { sensitivity: 'base', caseFirst: 'lower' });
                break;
            case 'discount':
                comparison = (b.discount || 0) - (a.discount || 0);
                break;
            case 'updatedAt':
                comparison = new Date(b.updatedAt || 0) - new Date(a.updatedAt || 0);
                break;
            case 'brand':
                const brandA = a.brand_en || a.brand_ar || '';
                const brandB = b.brand_en || b.brand_ar || '';
                comparison = brandA.localeCompare(brandB, locale, { sensitivity: 'base', caseFirst: 'lower' });
                break;
            default:
                break;
        }
        return isAscending ? (isReverseOrder ? -comparison : comparison) : (isReverseOrder ? comparison : -comparison);
    });

    const totalPages = Math.ceil(sortedProducts.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedProducts.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const renderPagination = () => {
        if (totalPages <= 1) return null;

        const paginationItems = [];

        // First page
        paginationItems.push(
            <Pagination.Item key={1} active={currentPage === 1} onClick={() => handlePaginationClick(1)}>
                1
            </Pagination.Item>
        );

        // Ellipsis before current page if needed
        if (currentPage > 2) {
            paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }

        // Current page
        if (currentPage > 1 && currentPage <= totalPages - 1) {
            paginationItems.push(
                <Pagination.Item key={currentPage} active onClick={() => handlePaginationClick(currentPage)}>
                    {currentPage}
                </Pagination.Item>
            );
        }

        // Ellipsis after current page if needed
        if (currentPage < totalPages - 1) {
            paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
        }

        // Last page
        if (totalPages > 1) {
            paginationItems.push(
                <Pagination.Item key={totalPages} active={currentPage === totalPages} onClick={() => handlePaginationClick(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return paginationItems;
    };

    const handlePaginationClick = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handleBrandChange = (brand) => {
        setSelectedBrand(brand);
        setCurrentPage(1);
        setIsDropdownOpen(false);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleSortChange = (sortOption) => {
        setSelectedSort(sortOption);
        setIsAscending(true);
        setCurrentPage(1);
    };


    return (
        <div>
            <Helmet>
                <title>{t("OfferpgaeH")}</title>
                <link rel="canonical" href="https://trendlineeg.com/" />
                <meta name="description" content={t("OfferpgaeH")} />
                <meta name="keywords" content={t("OfferpgaeH")} />
                <meta property="og:image" content={showCase} />
                <meta property="og:title" content={`TrendLine ${t("OfferpgaeH")}`} />
                <meta property="og:url" content="https://trendlineeg.com" />
                <meta property="og:description" lang="ar"
                    content="شركة ترندلاين متخصصة في تقديم حلول منخفضة التيار المتكاملة بما في ذلك أنظمة المراقبة بالفيديو عالية الدقة، وأنظمة التحكم في الوصول، وأنظمة الإنذار بالحريق، وأنظمة الأمان، والخوادم ووحدات التخزين الشبكية، وجدران الحماية، واللاب توب وأجهزة الكمبيوتر، وقطع الكمبيوتر، والشاشات والتلفزيونات، والشاشات التفاعلية، والألياف البصرية وUTP، وحلول WiMax وUbiquiti. تقدم الشركة خدمات البيع المباشر، التوصيل، التركيب، والبرمجة لعملاء الإلكترونيات، اللاب توب، السيرفرات، الكمبيوترات التجميع، السمارت هوم، جدران الحماية، أنظمة المراقبة، إدارة المحال التجارية، إدارة الفنادق، التوصيل الشبكي بالكابل، الواي فاي، الفايبر أوبتيكال، خدمة العملاء، السنترال الداخلي، ربط الفروع، الفيديو كول، الإنتركوم المرئي والصوتي، الساوند المركزي، الدش والستالايت، وأنظمة الألواح الشمسية" />
                <meta property="og:description" lang="en"
                    content="Trendline specializes in providing integrated low current solutions, including HD video surveillance, access control systems, fire alarm systems, security systems, servers and NAS storage, firewalls, laptops and PCs, PC hardware, monitors and TVs, interactive screens, fiber optics and UTP, and WiMax and Ubiquiti solutions. The company offers direct sales, delivery, installation, and programming services for electronics, laptops, servers, custom-built computers, smart home, firewalls, surveillance systems, retail management, hotel management, wired, Wi-Fi, and fiber optic network solutions, customer service systems, internal PBX, branch connectivity, video call systems, intercom (video and audio), central sound systems, satellite dishes, and solar panels." />
            </Helmet>
            <div className='OP-container'>
                <div className='brand-filter' ref={dropdownRef}>
                    <label className='filterItem' htmlFor="brandFilter" onClick={toggleDropdown}>{t('filterByBrand')}</label>
                    {isDropdownOpen && (
                        <div id="brandFilter">
                            <div className={`filterItem ${selectedBrand === '' ? 'selected' : ''}`} onClick={() => handleBrandChange('')}>{t('allBrands')}</div>
                            {Array.from(new Set(offerProducts.map(product => currentLanguage === 'en' ? product.brand_en : product.brand_ar)))
                                .map((brand, index) => (
                                    <div className={`filterItem ${selectedBrand === brand ? 'selected' : ''}`} key={index} onClick={() => handleBrandChange(brand)}>{brand}</div>
                                ))}
                        </div>
                    )}
                </div>
                <div className='sorting-container' dir={i18next.dir()}>
                    <div>{t("sortBy")}</div>
                    <div className={`filterItem ${selectedSort === 'name' ? 'selected' : ''}`} onClick={() => handleSortChange('name')}><FaSortAlphaUp /></div>
                    <div className={`filterItem ${selectedSort === 'discount' ? 'selected' : ''}`} onClick={() => handleSortChange('discount')}><RiDiscountPercentFill /></div>
                    <div className={`filterItem ${selectedSort === 'updatedAt' ? 'selected' : ''}`} onClick={() => handleSortChange('updatedAt')}>
                        <CgSandClock />
                    </div>
                    <div className={`filterItem ${selectedSort === 'brand' ? 'selected' : ''}`} onClick={() => handleSortChange('brand')}>{t('Brand')}</div>
                </div>
                <div className='sortOPS'>
                    <div className={`filterItem ${isReverseOrder ? 'selected' : ''}`} onClick={() => setIsReverseOrder(true)}><FaSortAmountUp /> </div>
                    <div className={`filterItem ${!isReverseOrder ? 'selected' : ''}`} onClick={() => setIsReverseOrder(false)}><FaSortAmountDownAlt /></div>
                </div>
            </div>
            <div className='pageH container d-flex' dir={i18next.dir()}>
                <Link to={"/"}> <IoHome /> </Link>
                &gt;
                <p>{t("OfferpgaeH")}</p>
            </div>
            <div className='pudactPage containerr'>
                <div className='prod-contanier '>
                    {loading ? (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: "0", left: "0", width: "100%", height: "100%", backgroundColor: "#fff" }}>
                            <GridLoader color="#3A5998" />
                        </div>
                    ) :
                        currentItems.length > 0 ? (
                            currentItems.map((item) => (
                                <ItemCard
                                    key={item.id}
                                    id={item.id}
                                    name_ar={currentLanguage === 'en' ? item.name_en : item.name_ar}
                                    img={item.imageProductCover}
                                    imgs={item.productImages.map(image => (image))}
                                    brand_ar={currentLanguage === 'en' ? item.brand_en : item.brand_ar}
                                    modle_ar={currentLanguage === 'en' ? item.model_en : item.model_ar}
                                    description={currentLanguage === 'en' ? item.description_en : item.description_ar}
                                    isOffer={item.isOffer}
                                    rating={item.rating}
                                    discount={item.discount}
                                    isNew={item.isNew}
                                />
                            ))
                        ) : (

                            <p>{t("comingSoon")}</p>
                        )}
                </div>
                <div className='PagiNation'>
                    {totalPages > 1 && (
                        <Pagination>
                            <Pagination.First onClick={() => handlePaginationClick(currentPage - 1)} disabled={currentPage === 1} />
                            {renderPagination()}
                            <Pagination.Last onClick={() => handlePaginationClick(currentPage + 1)} disabled={currentPage === totalPages} />
                        </Pagination>
                    )}
                </div>
            </div>
        </div>
    );
}

export default OffersPage;
