import './Navbar.css'
import navlogo from '../../../Assets/logo.png'




const Navbar = ({ handleLogout }) => {
    return (
        <div className="navbar">
            <div className='container'>
                <div className='admin-logo-content '>
                    <a href="/">
                        <img className='nav-logo' src={navlogo} alt="img" />
                    </a>
                </div>
                <div className='user-content'>
                    {/* <i><FaCircleUser className='nav-user' /></i> */}
                    <h4>Admin</h4>
                    {/* <button outline className='mx-2 px-5' color='white' size='lg' outline="true" onClick={handleLogout}>Logout</button> */}
                </div>
            </div>
        </div>
    )
}

export default Navbar