import React from 'react';
import "./StarsRating.css"
import { IoIosStar } from 'react-icons/io';
import { MdOutlineStarBorder, MdStarHalf } from 'react-icons/md';

const StarsRating = ({ rating }) => {
    // Ensure rating is a number and limit it between 0 and 5
    const normalizedRating = Math.max(0, Math.min(5, parseFloat(rating)));

    // Calculate full stars, half stars, and empty stars
    const fullStars = Math.floor(normalizedRating);
    const hasHalfStar = normalizedRating % 1 !== 0;
    const emptyStars = 5 - Math.ceil(normalizedRating);

    return (
        <div className="ratingStars" style={{ display: 'flex', alignItems: 'center', color: "#3A5998" }} dir='ltr'>
            {Array(fullStars).fill().map((_, index) => (
                <IoIosStar key={`full-${index}`} />
            ))}
            {hasHalfStar && <MdStarHalf />}
            {Array(emptyStars).fill().map((_, index) => (
                <MdOutlineStarBorder key={`empty-${index}`} />
            ))}
        </div>
    );
};

export default StarsRating;
