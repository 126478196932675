import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import servimg1 from '../Assets/catgcard-1.png'
import servimg2 from '../Assets/catgcard-2.png'
import servimg3 from '../Assets/catgcard-3.png'
import servimg4 from '../Assets/catgcard-4.png'
import servimg5 from '../Assets/catgcard-5.png'
import servimg6 from '../Assets/catgcard-6.png'


const ShopCompData = () => {
  const { t } = useTranslation();
  const [shopCompData, setSopCompData] = useState([])

  useEffect(() => {
    setSopCompData([
      {
        key: 1,
        gen_category: t("gen_category1"),
        img: servimg1,
      },
      {
        key: 2,
        gen_category: t("gen_category2"),
        img: servimg2,
      },
      {
        key: 3,
        gen_category: t("gen_category3"),
        img: servimg3,
      },
      {
        key: 4,
        gen_category: t("gen_category4"),
        img: servimg4,
      },
      {
        key: 5,
        gen_category: t("gen_category5"),
        img: servimg5,
      },
      {
        key: 6,
        gen_category: t("gen_category6"),
        img: servimg6,
      }

    ]);
  }, [t]);

  return shopCompData;
}

export default ShopCompData
