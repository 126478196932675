import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend';
import dataAR from './locales/ar/translation.json'
import dataEN from './locales/en/translation.json'



const resources = {
    en: {
        translation: dataEN,
    },
    ar: {
        translation: dataAR
    },
};

const customLanguageDetector = {
    type: 'languageDetector',
    async: false,
    detect: () => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('lang') || 'ar';
    },
    init: () => { },
    cacheUserLanguage: (lang) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('lang', lang);
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
        window.history.replaceState(null, null, newUrl);
    }
};



i18n
    .use(customLanguageDetector)
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(HttpApi)
    .init({
        resources,
        fallbackLng: "ar",
        detection: {
            order: [
                'customDetector',
                'cookie',
                'htmlTag',
                'localStorage',
                'sessionStorage',
                'navigator',
                'htmlTag',
                'path',
                'subdomain'
            ],
            caches: ["cookie"],
        },
        backend: {
            loadPath: './Locale/{{lang}}/translation.json',
        },
    });

export default i18n;

