import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios'; // Ensure axios is installed and imported


// Create a Context
export const DataContext = createContext();

// Create a Provider component
export const DataProvider = ({ children }) => {
    const [data, setData] = useState(() => {
        // Load data from sessionStorage if it exists
        const savedData = sessionStorage.getItem('data');
        return savedData ? JSON.parse(savedData) : null;
    });

    useEffect(() => {
        // Fetch data if it's not already loaded
        if (!data) {
            const fetchData = async () => {
                try {
                    // Replace 'https://example.com/api/data' with your API endpoint
                    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/general/all-products`);
                    const fetchedData = response.data;
                    setData(fetchedData);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

            fetchData();
        }
    }, [data]);

    useEffect(() => {
        // Save data to sessionStorage whenever it changes
        if (data) {
            sessionStorage.setItem('data', JSON.stringify(data));
        }
    }, [data]);

    const updateData = (newData) => {
        setData(newData);
    };

    return (
        <DataContext.Provider value={{ data, updateData }}>
            {children}
        </DataContext.Provider>
    );
};
