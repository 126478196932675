import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'


const NavDropData2 = () => {
    const { t } = useTranslation();
    const [navDropData2, setNavDropData2] = useState([]);



    useEffect(() => {
        setNavDropData2([
            {
                key: 1,
                nav_d2_category: t("nav_d2_category1"),
                gen_category: t("sec-nav2"),
            },
            {
                key: 2,
                nav_d2_category: t("nav_d2_category2"),
                gen_category: t("sec-nav2"),
            },
            {
                key: 3,
                nav_d2_category: t("nav_d2_category3"),
                gen_category: t("sec-nav2"),
            },
            {
                key: 4,
                nav_d2_category: t("nav_d2_category4"),
                gen_category: t("sec-nav2"),
            }

        ]);
    }, [t]);

    return navDropData2;

}

export default NavDropData2
