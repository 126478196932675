import React from 'react';
import { Link } from 'react-router-dom';
import ShopCompData from '../../localData/shopCompData';
import { useTranslation } from 'react-i18next';

const Shopcomp = () => {
    const { t } = useTranslation();
    const categories = ShopCompData(); // Using the local array directly

    return (
        <section id='shopcomp' className='shopcomp'>
            <div className='sectionH'>
                <h2><span>{t("CategoryH")}</span></h2>
            </div>
            <div className='container-'>
                <div className='serv-item catgitems'>
                    {categories.slice(0, 6).map((category, index) => (
                        <div className='catcontainer-' key={index}>
                            <Link to={`/category/${category.gen_category}`}>
                                <div className='serv-item catgitem'>
                                    <img src={category.img} alt={`${category.gen_category}_image`} />
                                    <h2 className='serv-content catgcontent'>{category.gen_category}</h2>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Shopcomp;
