import React, { createContext, useContext } from 'react';
import axios from 'axios';

const ApiContext = createContext();

export const useApi = () => useContext(ApiContext);

export const ApiProvider = ({ children }) => {
    const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

    const apiRequest = async (method, endpoint, data = null) => {
        try {
            const response = await axios({
                method,
                url: `${baseUrl}${endpoint}`,
                data,
            });
            return response.data;
        } catch (error) {
            console.error('API request error:', error);
            throw error;
        }
    };

    return (
        <ApiContext.Provider value={{ apiRequest }}>
            {children}
        </ApiContext.Provider>
    );
};
