import React, { useState, useEffect } from 'react';
import uploadImg from '../../../Assets/AdminAssets/upload_image.png';
import './AddProduct.css';
import axios from 'axios';

const AddProduct = () => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [formData, setFormData] = useState({
        name_ar: '',
        name_en: '',
        brand_ar: '',
        brand_en: '',
        model_ar: '',
        model_en: '',
        description_ar: '',
        description_en: '',
        rating: 0,
        reviews: 0,
        offerExpiresAt: '',
        isNew: false,
        isOffer: false,
        discount: 0,
        productImages: [],
        imageProductCover: null
    });
    const [error, setError] = useState('');

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            console.error('No token found. Please login.');
            setError('No token found. Please login.');
            return;
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/categories/sub-categories?lang=ar`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                setCategories(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the categories!', error);
                setError('There was an error fetching the categories.');
            });
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        const token = sessionStorage.getItem('token');
        if (!token) {
            setError('No token found. Please login.');
            window.alert('No token found. Please login.')
            return;
        }

        if (!selectedCategory) {
            setError('Please select a category.');
            window.alert('Please select a category.')
            return;
        }

        if (!formData.name_ar) {
            setError('Please Enter name_ar.');
            window.alert('Please Enter name_ar.')
            return;
        }
        if (!formData.name_en) {
            setError('Please Enter name_en.');
            window.alert('Please Enter name_en.')
            return;
        }
        if (!formData.brand_ar) {
            setError('Please Enter brand_ar.');
            window.alert('Please Enter brand_ar.')
            return;
        }
        if (!formData.brand_en) {
            setError('Please Enter brand_en.');
            window.alert('Please Enter brand_en.')
            return;
        }
        if (!formData.model_ar) {
            setError('Please Enter model_ar.');
            window.alert('Please Enter model_ar.')
            return;
        }
        if (!formData.model_en) {
            setError('Please Enter model_en.');
            window.alert('Please Enter model_en.')
            return;
        }
        if (!formData.description_ar) {
            setError('Please Enter description_ar.');
            window.alert('Please Enter description_ar.')
            return;
        }
        if (!formData.description_en) {
            setError('Please Enter description_en.');
            window.alert('Please Enter description_en.')
            return;
        }
        setError('');

        if (!formData.imageProductCover) {
            setError('Please Enter Image Product Cover.');
            window.alert('Please Enter Image Product Cover.')
            return;
        }
        if (!formData.productImages.length) {
            setError('Please Enter product Images.');
            window.alert('Please Enter product Images.');
            return;
        }


        if (formData.isOffer && !formData.offerExpiresAt) {
            setError('Please provide an offer expiration date.');
            window.alert('Please provide an offer expiration date.')
            return;
        }

        if (formData.isOffer && (!formData.discount || formData.discount <= 0)) {
            setError('Discount must be greater than 0.');
            window.alert('Discount must be greater than 0.')
            return;
        }

        const data = new FormData();
        for (let key in formData) {
            if (key === 'productImages') {
                for (let i = 0; i < formData.productImages.length; i++) {
                    data.append('productImages', formData.productImages[i]);
                }
            } else if (key === 'imageProductCover') {
                data.append('imageProductCover', formData.imageProductCover[0]);
            } else if (key === 'offerExpiresAt' && !formData.isOffer) {
                // Skip offerExpiresAt if isOffer is unchecked
                continue;
            } else {
                data.append(key, formData[key]);
            }
        }

        axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/products/${selectedCategory}/product/add`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(() => {
                window.alert('Product added successfully \n تم اضافة المنتج بنجاح');
                setError(''); // Clear any previous error messages
                window.location.reload(); // Reload the page
            })
            .catch(error => {
                console.error('Error details:', error);
                const errorMsg = error.response ? error.response.data.message : 'An unexpected error occurred.';
                setError(errorMsg);
                window.alert(`There was an error adding the product! \n ${errorMsg}`);
            });
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;

        if (type === 'checkbox' && name === 'reviews') {
            setFormData({ ...formData, reviews: checked ? 100 : 0 });
        } else if (name === 'isOffer') {
            setFormData({
                ...formData,
                [name]: checked,
                offerExpiresAt: '',
                discount: checked ? formData.discount : 0 // Reset or keep discount based on checkbox
            });
        } else {
            const newValue = type === 'checkbox' ? checked : value;
            setFormData({
                ...formData,
                [name]: newValue
            });
        }
    };

    const handleFileChange = (event) => {
        const { name, files } = event.target;
        setFormData({
            ...formData,
            [name]: files
        });
    };


    return (
        <div className="add-product">
            <div className="admin-page-container">
                <div className="addproduct-itemfield">
                    <div className="product-category">
                        <p>select category - اختر الفئة</p>
                        <select onChange={handleCategoryChange} value={selectedCategory}>
                            <option value="">Select a category</option>
                            {categories.map(category => (
                                <option key={category.id} value={category.id}>{category.name}</option>
                            ))}
                        </select>
                    </div>
                </div>

                {error && <p className="text-danger">{error}</p>}

                <form onSubmit={handleSubmit}>
                    <div className="addproduct-itemfield">
                        <div className="product-en">
                            <p>Product Name</p>
                            <input type="text" name="name_en" value={formData.name_en} onChange={handleInputChange} placeholder="Name (EN)" />
                        </div>
                        <div className="product-ar" dir='rtl'>
                            <p>أسم المنتج</p>
                            <input type="text" name="name_ar" value={formData.name_ar} onChange={handleInputChange} placeholder="Name (AR)" />
                        </div>
                    </div>

                    <div className="addproduct-brand">
                        <div className="addproduct-itemfield">
                            <div className="product-en">
                                <p>Brand Name</p>
                                <input type="text" name="brand_en" value={formData.brand_en} onChange={handleInputChange} placeholder="Brand (EN)" />
                            </div>
                            <div className="product-ar" dir='rtl'>
                                <p>أسم الماركة</p>
                                <input type="text" name="brand_ar" value={formData.brand_ar} onChange={handleInputChange} placeholder="Brand (AR)" />
                            </div>
                        </div>
                    </div>

                    <div className="addproduct-model">
                        <div className="addproduct-itemfield">
                            <div className="product-en">
                                <p>Model Name</p>
                                <input type="text" name="model_en" value={formData.model_en} onChange={handleInputChange} placeholder="Model (EN)" />
                            </div>
                            <div className="product-ar" dir='rtl'>
                                <p>أسم الصنف</p>
                                <input type="text" name="model_ar" value={formData.model_ar} onChange={handleInputChange} placeholder="Model (AR)" />
                            </div>
                        </div>
                    </div>

                    <div className="addproduct-dic">
                        <div className="addproduct-itemfield">
                            <div>
                                <p>Description</p>
                                <textarea type="text" name="description_en" value={formData.description_en} onChange={handleInputChange} placeholder="Description (EN)" />
                            </div>
                            <div className="product-ar" dir='rtl'>
                                <p>الوصف</p>
                                <textarea type="text" name="description_ar" value={formData.description_ar} onChange={handleInputChange} placeholder="Description (AR)" />
                            </div>
                        </div>
                    </div>
                    <div className="addproduct-dic">
                        <div className="addproduct-itemfield">
                            <div>
                                <p>Rating - التقيم</p>
                                <input type="number" name="rating" value={formData.rating} onChange={handleInputChange} placeholder="Rating - التقيم" step={0.5} min={0} max={5} />
                            </div>
                            <div>
                                <p>Reviews - المراجعات</p>
                                <input type="number" name="reviews" value={formData.reviews} onChange={handleInputChange} placeholder="Reviews - المراجعات" />
                            </div>
                        </div>
                    </div>

                    <div className="addproduct-itemfield">
                        <div>
                            <label htmlFor="file-input">
                                <img className='addproduct-thumnail-img' src={formData.imageProductCover ? URL.createObjectURL(formData.imageProductCover[0]) : uploadImg} alt="img" />
                            </label>
                            <input type="file" name="imageProductCover" id="file-input" hidden onChange={handleFileChange} />
                        </div>

                        <div dir='rtl'>
                            {formData.productImages.length > 0 && (
                                <div className='addproduct-thumnail-imgs'>
                                    {Array.from(formData.productImages).map((file, index) => (
                                        <img key={index} className='addproduct-thumnail-img' src={URL.createObjectURL(file)} alt={`ProductImage ${index + 1}`} />
                                    ))}
                                </div>
                            )}
                            <input type="file" name="productImages" onChange={handleFileChange} multiple />
                        </div>
                    </div>

                    <div className="addproduct-itemfieldd">
                        <div className="product-en">
                            <p>discount - خصم</p>
                            <input type="number" name="discount" value={formData.discount} onChange={handleInputChange} placeholder="20%" />
                        </div>

                        <div className="product-en">
                            <p>انتهاء العرض</p>
                            <input type="date" name="offerExpiresAt" value={formData.offerExpiresAt} onChange={handleInputChange} />
                        </div>
                        <div className='chick-boxs'>
                            <label>
                                <input
                                    type="checkbox"
                                    name="reviews"
                                    checked={formData.reviews}
                                    onChange={handleInputChange}
                                />
                                Best Seller - اكثر مبيعاً
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="isNew"
                                    checked={formData.isNew}
                                    onChange={handleInputChange}
                                />
                                Is New - جديد
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="isOffer"
                                    checked={formData.isOffer}
                                    onChange={handleInputChange}
                                />
                                Is Offer - عرض
                            </label>
                        </div>
                    </div>

                    <button type="submit">Add Product</button>
                </form>
            </div>
        </div>
    );
};

export default AddProduct;
