import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
// import HomeContextProvider from './Context/HomeContext.jsx';
import { ApiProvider } from './Context/ApiProvider.jsx'; // Add curly braces and fix the path if needed
import { DataProvider } from './Context/DataProvider.jsx'
import App from './App';
import './index.css';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApiProvider>
    <DataProvider>
      <App />
    </DataProvider>
  </ApiProvider>
);

reportWebVitals();
