import servimg1 from '../Assets/servCards/serv-1.png'
import servimg2 from '../Assets/servCards/serv-2.png'
import servimg3 from '../Assets/servCards/serv-3.png'
import servimg4 from '../Assets/servCards/serv-4.png'
import servimg5 from '../Assets/servCards/serv-5.png'
import servimg6 from '../Assets/servCards/serv-6.png'
import servimg7 from '../Assets/servCards/serv-7.png'
import servimgL from '../Assets/servCards/serv8.png'
import servimg9 from '../Assets/servCards/serv-9.png'
import servimg10 from '../Assets/servCards/serv-10.png'
import servimg11 from '../Assets/servCards/serv-11.png'
import servimg12 from '../Assets/servCards/serv-12.png'
import servimg13 from '../Assets/servCards/serv-13.png'
import servimg14 from '../Assets/servCards/serv-14.png'
import servimg15 from '../Assets/servCards/serv-15.png'
import servimg16 from '../Assets/servCards/serv-16.png'
import servimg17 from '../Assets/servCards/serv-17.png'
import servimg18 from '../Assets/servCards/serv-18.png'
import servimg19 from '../Assets/servCards/serv-19.png'
import servimg20 from '../Assets/servCards/serv-20.png'
import servimg21 from '../Assets/servCards/serv-21.png'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'



const ServicesCards = () => {


  const { t } = useTranslation();

  const [servicesCards, setServicesCards] = useState([]);

  useEffect(() => {
    setServicesCards([
      {
        id: 1,
        img: servimg12,
        Serv_name: t('servname12'),
        desH1: t('des12H1'),
        desH2: t('des12H2'),
        desH3: t('des12H3'),
        desH4: t('des12H4'),
        desH5: t('des12H5'),
        desH6: t('des12H6'),
        desH7: t('des12H7'),
        desH8: t('des12H8'),
        desH9: t('des12H9'),

        desP1: t('des12P1'),
        desP2: t('des12P2'),
        desP3: t('des12P3'),
        desP4: t('des12P4'),
        desP5: t('des12P5'),
        desP6: t('des12P6'),
        desP7: t('des12P7'),
        desP8: t('des12P8'),
        desP9: t('des12P9'),
      },
      {
        id: 2,
        img: servimg2,
        gen_category: t('sev_category1'),
        Serv_name: t('servname2'),
        desH1: t('des2H1'),
        desH2: t('des2H2'),
        desH3: t('des2H3'),
        desH4: t('des2H4'),
        desH5: t('des2H5'),
        desH6: t('des2H6'),
        desH7: t('des2H7'),
        desH8: t('des2H8'),
        desH9: t('des2H9'),

        desP1: t('des2P1'),
        desP2: t('des2P2'),
        desP3: t('des2P3'),
        desP4: t('des2P4'),
        desP5: t('des2P5'),
        desP6: t('des2P6'),
        desP7: t('des2P7'),
        desP8: t('des2P8'),
        desP9: t('des2P9'),
      },
      {
        id: 3,
        img: servimg3,
        gen_category: t('sev_category1'),
        Serv_name: t('servname3'),
        desH1: t('des3H1'),
        desH2: t('des3H2'),
        desH3: t('des3H3'),
        desH4: t('des3H4'),
        desH5: t('des3H5'),
        desH6: t('des3H6'),
        desH7: t('des3H7'),
        desH8: t('des3H8'),
        desH9: t('des3H9'),

        desP1: t('des3P1'),
        desP2: t('des3P2'),
        desP2br1: t('des3P2br1'),
        desP3: t('des3P3'),
        desP4: t('des3P4'),
        desP5: t('des3P5'),
        desP6: t('des3P6'),
        desP7: t('des3P7'),
        desP8: t('des3P8'),
        desP9: t('des3P9'),
        productBTN: `/category/راوتر/products`,
        productBTNH: t("SBtnH")
      },
      {
        id: 4,
        img: servimg4,
        gen_category: t('sev_category2'),
        Serv_name: t('servname4'),
        desH1: t('des4H1'),
        desH2: t('des4H2'),
        desH3: t('des4H3'),
        desH4: t('des4H4'),
        desH5: t('des4H5'),
        desH6: t('des4H6'),
        desH7: t('des4H7'),
        desH8: t('des4H8'),
        desH9: t('des4H9'),

        desP1: t('des4P1'),
        desP2: t('des4P2'),
        desP3: t('des4P3'),
        desP4: t('des4P4'),
        desP5: t('des4P5'),
        desP6: t('des4P6'),
        desP7: t('des4P7'),
        desP8: t('des4P8'),
        desP9: t('des4P9'),
      },
      {
        id: 5,
        img: servimg5,
        gen_category: t('sev_category2'),
        Serv_name: t('servname5'),
        desH1: t('des5H1'),
        desH2: t('des5H2'),
        desH3: t('des5H3'),
        desH4: t('des5H4'),
        desH5: t('des5H5'),
        desH6: t('des5H6'),
        desH7: t('des5H7'),
        desH8: t('des5H8'),
        desH9: t('des5H9'),

        desP1: t('des5P1'),
        desP2: t('des5P2'),
        desP3: t('des5P3'),
        desP4: t('des5P4'),
        desP5: t('des5P5'),
        desP6: t('des5P6'),
        desP7: t('des5P7'),
        desP8: t('des5P8'),
        desP9: t('des5P9'),
      },
      {
        id: 6,
        img: servimg6,
        gen_category: t('sev_category2'),
        Serv_name: t('servname6'),
        desH1: t('des6H1'),
        desH2: t('des6H2'),
        desH3: t('des6H3'),
        desH4: t('des6H4'),
        desH5: t('des6H5'),
        desH6: t('des6H6'),
        desH7: t('des6H7'),
        desH8: t('des6H8'),
        desH9: t('des6H9'),

        desP1: t('des6P1'),
        desP2: t('des6P2'),
        desP3: t('des6P3'),
        desP4: t('des6P4'),
        desP5: t('des6P5'),
        desP6: t('des6P6'),
        desP7: t('des6P7'),
        desP8: t('des6P8'),
        desP9: t('des6P9'),
      },
      {
        id: 7,
        img: servimg7,
        Serv_name: t('servname7'),
        desH1: t('des7H1'),
        desH2: t('des7H2'),
        desH3: t('des7H3'),
        desH4: t('des7H4'),
        desH5: t('des7H5'),
        desH6: t('des7H6'),
        desH7: t('des7H7'),
        desH8: t('des7H8'),
        desH9: t('des7H9'),

        desP1: t('des7P1'),
        desP2: t('des7P2'),
        desP3: t('des7P3'),
        desP4: t('des7P4'),
        desP5: t('des7P5'),
        desP6: t('des7P6'),
        desP7: t('des7P7'),
        desP8: t('des7P8'),
        desP9: t('des7P9'),
      },
      {
        id: 8,
        img: servimgL,
        Serv_name: t('servname8'),
        desH1: t('des8H1'),
        desH2: t('des8H2'),
        desH3: t('des8H3'),
        desH4: t('des8H4'),
        desH5: t('des8H5'),
        desH6: t('des8H6'),
        desH7: t('des8H7'),
        desH8: t('des8H8'),
        desH9: t('des8H9'),

        desP1: t('des8P1'),
        desP2: t('des8P2'),
        desP3: t('des8P3'),
        desP4: t('des8P4'),
        desP5: t('des8P5'),
        desP6: t('des8P6'),
        desP7: t('des8P7'),
        desP8: t('des8P8'),
        desP9: t('des8P9'),
      },
      {
        id: 9,
        img: servimg9,
        Serv_name: t('servname9'),
        desH1: t('des9H1'),
        desH2: t('des9H2'),
        desH3: t('des9H3'),
        desH4: t('des9H4'),
        desH5: t('des9H5'),
        desH6: t('des9H6'),
        desH7: t('des9H7'),
        desH8: t('des9H8'),
        desH9: t('des9H9'),

        desP1: t('des9P1'),
        desP2: t('des9P2'),
        desP3: t('des9P3'),
        desP4: t('des9P4'),
        desP5: t('des9P5'),
        desP6: t('des9P6'),
        desP7: t('des9P7'),
        desP8: t('des9P8'),
        desP9: t('des9P9'),
      },
      {
        id: 10,
        img: servimg10,
        Serv_name: t('servname10'),
        desH1: t('des10H1'),
        desH2: t('des10H2'),
        desH3: t('des10H3'),
        desH4: t('des10H4'),
        desH5: t('des10H5'),
        desH6: t('des10H6'),
        desH7: t('des10H7'),
        desH8: t('des10H8'),
        desH9: t('des10H9'),

        desP1: t('des10P1'),
        desP2: t('des10P2'),
        desP3: t('des10P3'),
        desP4: t('des10P4'),
        desP5: t('des10P5'),
        desP6: t('des10P6'),
        desP7: t('des10P7'),
        desP8: t('des10P8'),
        desP9: t('des10P9'),
      },
      {
        id: 11,
        img: servimg11,
        Serv_name: t('servname11'),
        desH1: t('des11H1'),
        desH2: t('des11H2'),
        desH3: t('des11H3'),
        desH4: t('des11H4'),
        desH5: t('des11H5'),
        desH6: t('des11H6'),
        desH7: t('des11H7'),
        desH8: t('des11H8'),
        desH9: t('des11H9'),

        desP1: t('des11P1'),
        desP2: t('des11P2'),
        desP3: t('des11P3'),
        desP4: t('des11P4'),
        desP5: t('des11P5'),
        desP6: t('des11P6'),
        desP7: t('des11P7'),
        desP8: t('des11P8'),
        desP9: t('des11P9'),
      },
      {


        id: 12,
        img: servimg1,
        gen_category: t('sev_category1'),
        Serv_name: t('servname1'),
        desH1: t('des1H1'),
        desH2: t('des1H2'),
        desH3: t('des1H3'),
        desH4: t('des1H4'),
        desH5: t('des1H5'),
        desH6: t('des1H6'),
        desH7: t('des1H7'),
        desH8: t('des1H8'),
        desH9: t('des1H9'),

        desP1: t('des1P1'),
        desP2: t('des1P2'),
        desP3: t('des1P3'),
        desP4: t('des1P4'),
        desP5: t('des1P5'),
        desP6: t('des1P6'),
        desP7: t('des1P7'),
        desP8: t('des1P8'),
        desP9: t('des1P9'),
        productBTN: "",
        productBTNH: t('SBtnH')
      },
      {
        id: 13,
        img: servimg13,
        Serv_name: t('servname13'),
        desH1: t('des13H1'),
        desH2: t('des13H2'),
        desH3: t('des13H3'),
        desH4: t('des13H4'),
        desH5: t('des13H5'),
        desH6: t('des13H6'),
        desH7: t('des13H7'),
        desH8: t('des13H8'),
        desH9: t('des13H9'),

        desP1: t('des13P1'),
        desP2: t('des13P2'),
        desP3: t('des13P3'),
        desP4: t('des13P4'),
        desP5: t('des13P5'),
        desP6: t('des13P6'),
        desP7: t('des13P7'),
        desP8: t('des13P8'),
        desP9: t('des13P9'),
      },
      {
        id: 14,
        img: servimg14,
        Serv_name: t('servname14'),
        desH1: t('des14H1'),
        desH2: t('des14H2'),
        desH3: t('des14H3'),
        desH4: t('des14H4'),
        desH5: t('des14H5'),
        desH6: t('des14H6'),
        desH7: t('des14H7'),
        desH8: t('des14H8'),
        desH9: t('des14H9'),

        desP1: t('des14P1'),
        desP2: t('des14P2'),
        desP3: t('des14P3'),
        desP4: t('des14P4'),
        desP5: t('des14P5'),
        desP6: t('des14P6'),
        desP7: t('des14P7'),
        desP8: t('des14P8'),
        desP9: t('des14P9'),
      },
      {
        id: 15,
        img: servimg15,
        Serv_name: t('servname15'),
        desH1: t('des15H1'),
        desH2: t('des15H2'),
        desH3: t('des15H3'),
        desH4: t('des15H4'),
        desH5: t('des15H5'),
        desH6: t('des15H6'),
        desH7: t('des15H7'),
        desH8: t('des15H8'),
        desH9: t('des15H9'),

        desP1: t('des15P1'),
        desP2: t('des15P2'),
        desP3: t('des15P3'),
        desP4: t('des15P4'),
        desP5: t('des15P5'),
        desP6: t('des15P6'),
        desP7: t('des15P7'),
        desP8: t('des15P8'),
        desP9: t('des15P9'),
      },
      {
        id: 16,
        img: servimg16,
        Serv_name: t('servname16'),
        desH1: t('des16H1'),
        desH2: t('des16H2'),
        desH3: t('des16H3'),
        desH4: t('des16H4'),
        desH5: t('des16H5'),
        desH6: t('des16H6'),
        desH7: t('des16H7'),
        desH8: t('des16H8'),
        desH9: t('des16H9'),

        desP1: t('des16P1'),
        desP2: t('des16P2'),
        desP3: t('des16P3'),
        desP4: t('des16P4'),
        desP5: t('des16P5'),
        desP6: t('des16P6'),
        desP7: t('des16P7'),
        desP8: t('des16P8'),
        desP9: t('des16P9'),
      },
      {
        id: 17,
        img: servimg17,
        Serv_name: t('servname17'),
        desH1: t('des17H1'),
        desH2: t('des17H2'),
        desH3: t('des17H3'),
        desH4: t('des17H4'),
        desH5: t('des17H5'),
        desH6: t('des17H6'),
        desH7: t('des17H7'),
        desH8: t('des17H8'),
        desH9: t('des17H9'),

        desP1: t('des17P1'),
        desP2: t('des17P2'),
        desP3: t('des17P3'),
        desP4: t('des17P4'),
        desP5: t('des17P5'),
        desP6: t('des17P6'),
        desP7: t('des17P7'),
        desP8: t('des17P8'),
        desP9: t('des17P9'),
      },
      {
        id: 18,
        img: servimg18,
        Serv_name: t('servname18'),
        desH1: t('des18H1'),
        desH2: t('des18H2'),
        desH3: t('des18H3'),
        desH4: t('des18H4'),
        desH5: t('des18H5'),
        desH6: t('des18H6'),
        desH7: t('des18H7'),
        desH8: t('des18H8'),
        desH9: t('des18H9'),

        desP1: t('des18P1'),
        desP2: t('des18P2'),
        desP3: t('des18P3'),
        desP4: t('des18P4'),
        desP5: t('des18P5'),
        desP6: t('des18P6'),
        desP7: t('des18P7'),
        desP8: t('des18P8'),
        desP9: t('des18P9'),
      },
      {
        id: 19,
        img: servimg19,
        Serv_name: t('servname19'),
        desH1: t('des19H1'),
        desH2: t('des19H2'),
        desH3: t('des19H3'),
        desH4: t('des19H4'),
        desH5: t('des19H5'),
        desH6: t('des19H6'),
        desH7: t('des19H7'),
        desH8: t('des19H8'),
        desH9: t('des19H9'),

        desP1: t('des19P1'),
        desP2: t('des19P2'),
        desP3: t('des19P3'),
        desP4: t('des19P4'),
        desP5: t('des19P5'),
        desP6: t('des19P6'),
        desP7: t('des19P7'),
        desP8: t('des19P8'),
        desP9: t('des19P9'),
      },
      {
        id: 20,
        img: servimg20,
        Serv_name: t('servname20'),
        desH1: t('des20H1'),
        desH2: t('des20H2'),
        desH3: t('des20H3'),
        desH4: t('des20H4'),
        desH5: t('des20H5'),
        desH6: t('des20H6'),
        desH7: t('des20H7'),
        desH8: t('des20H8'),
        desH9: t('des20H9'),

        desP1: t('des20P1'),
        desP2: t('des20P2'),
        desP3: t('des20P3'),
        desP4: t('des20P4'),
        desP5: t('des20P5'),
        desP6: t('des20P6'),
        desP7: t('des20P7'),
        desP8: t('des20P8'),
        desP9: t('des20P9'),
      },
      {
        id: 21,
        img: servimg21,
        Serv_name: t('servname21'),
        desH1: t('des21H1'),
        desH2: t('des21H2'),
        desH3: t('des21H3'),
        desH4: t('des21H4'),
        desH5: t('des21H5'),
        desH6: t('des21H6'),
        desH7: t('des21H7'),
        desH8: t('des21H8'),
        desH9: t('des21H9'),

        desP1: t('des21P1'),
        desP2: t('des21P2'),
        desP3: t('des21P3'),
        desP4: t('des21P4'),
        desP5: t('des21P5'),
        desP6: t('des21P6'),
        desP7: t('des21P7'),
        desP8: t('des21P8'),
        desP9: t('des21P9'),
      },
      // Add other service objects similarly...
    ]);
  }, [t]);

  return servicesCards;
};
export default ServicesCards;


