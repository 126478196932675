import React from 'react'
import ServicesCards from '../localData/ServicesData'
import { Link } from 'react-router-dom';

function ServCard() {
    const servicesData = ServicesCards();


    return (
        <div className='serv-items catgitems'>
            {servicesData.map(service => (
                <div className='catcontainer' key={service.id}>
                    <Link to={`/services/${service.id}/${service.gen_category}/${service.Serv_name}`}>
                        <div className='serv-item catgitem'>
                            <img src={service.img} alt='img' />
                            <h2 className='serv-content catgcontent'>{service.Serv_name}</h2>
                        </div>

                    </Link>
                </div>
            ))}
        </div>
    )
}

export default ServCard