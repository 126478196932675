import React, { useEffect, useState } from 'react';
import './AddCategory.css';
import axios from 'axios';
import uploadImg from '../../../Assets/AdminAssets/upload_image.png';

const AddCategory = () => {
    const [categoryImage, setCategoryImage] = useState(null);
    const [categoryDetails, setCategoryDetails] = useState({
        categoryName_en: '',
        categoryName_ar: '',
        category_en: '',
        category_ar: '',
    });

    const [categories_en, setCategories_en] = useState([]);
    const [categories_ar, setCategories_ar] = useState([]);
    const [selectedCategory_en, setSelectedCategory_en] = useState('');
    const [selectedCategory_ar, setSelectedCategory_ar] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        // Fetch categories in English
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/categories?lang=en`)
            .then(response => {
                if (response.data && Array.isArray(response.data)) {
                    setCategories_en(response.data);
                } else if (response.data && response.data.gen_category) {
                    setCategories_en(response.data.gen_category);
                } else {
                    console.error('Invalid API response format (English):', response);
                }
            })
            .catch(error => {
                console.error('Error fetching categories (English):', error);
            });

        // Fetch categories in Arabic
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/categories?lang=ar`)
            .then(response => {
                if (response.data && Array.isArray(response.data)) {
                    setCategories_ar(response.data);
                } else if (response.data && response.data.gen_category) {
                    setCategories_ar(response.data.gen_category);
                } else {
                    console.error('Invalid API response format (Arabic):', response);
                }
            })
            .catch(error => {
                console.error('Error fetching categories (Arabic):', error);
            });
    }, []);

    const imageHandler = (e) => {
        setCategoryImage(e.target.files[0]);
    };

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setCategoryDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const addCategory = async () => {
        try {
            const token = sessionStorage.getItem('token');
            if (!token) {
                console.error('No token found. Please login.');
                return;
            };

            if (!categoryDetails.category_ar) {
                setError('Please Enter or Select Main Category Name_ar.');
                window.alert('Please Enter or Select Main Category Name_ar.')
                return;
            };
            if (!categoryDetails.category_en) {
                setError('Please Enter or Select Main Category Name_en.');
                window.alert('Please Enter or Select Main Category Name_en.')
                return;
            };
            if (!categoryDetails.categoryName_ar) {
                setError('Please Enter Category Name_ar.');
                window.alert('Please Enter Category Name_ar.')
                return;
            };
            if (!categoryDetails.categoryName_en) {
                setError('Please Enter Category Name_en.');
                window.alert('Please Enter Category Name_en.')
                return;
            };
            if (!categoryImage) {
                setError('Please Enter Category Image.');
                window.alert('Please Enter Category Image.')
                return;
            };
            setError('');


            const formData = new FormData();
            formData.append('categoryImage', categoryImage);

            // Append only the expected fields
            formData.append('categoryName_en', categoryDetails.categoryName_en);
            formData.append('categoryName_ar', categoryDetails.categoryName_ar);
            formData.append('category_en', categoryDetails.category_en);
            formData.append('category_ar', categoryDetails.category_ar);

            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/products/category/add`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });



            window.alert('Category added successfully \n تم اضافة الصنف', response.data);

            // Reload the page after successful submission
            window.location.reload();
        } catch (err) {
            console.error('Error adding Category \n خطء في أضافه الصنف:', err.response || err.message || err);
            window.alert(`Error adding product: ${err.response?.data?.message || err.message}`);
        }
    };

    const handleCategoryChange_en = (event) => {
        const selectedValue = event.target.value;
        setSelectedCategory_en(selectedValue);

        // Find the selected category object in English
        const selectedCategoryObject = categories_en.find(category => category.id === selectedValue);

        // Update the category_en in categoryDetails
        if (selectedCategoryObject) {
            setCategoryDetails(prevDetails => ({
                ...prevDetails,
                category_en: selectedCategoryObject.gen_category,
            }));
        }

        // Update corresponding category in Arabic if already selected
        const correspondingCategoryArabic = categories_ar.find(category => category.id === selectedValue);
        if (correspondingCategoryArabic) {
            setSelectedCategory_ar(correspondingCategoryArabic.id);
            setCategoryDetails(prevDetails => ({
                ...prevDetails,
                category_ar: correspondingCategoryArabic.gen_category,
            }));
        }
    };

    const handleCategoryChange_ar = (event) => {
        const selectedValue = event.target.value;
        setSelectedCategory_ar(selectedValue);

        // Find the selected category object in Arabic
        const selectedCategoryObject = categories_ar.find(category => category.id === selectedValue);

        // Update the category_ar in categoryDetails
        if (selectedCategoryObject) {
            setCategoryDetails(prevDetails => ({
                ...prevDetails,
                category_ar: selectedCategoryObject.gen_category,
            }));
        }

        // Update corresponding category in English if already selected
        const correspondingCategoryEnglish = categories_en.find(category => category.id === selectedValue);
        if (correspondingCategoryEnglish) {
            setSelectedCategory_en(correspondingCategoryEnglish.id);
            setCategoryDetails(prevDetails => ({
                ...prevDetails,
                category_en: correspondingCategoryEnglish.gen_category,
            }));
        }
    };

    // New category options
    const newCategory = {
        id: 1,
        name_en: "New main category",
        name_ar: "صنف رئيسي جديد +",
    };

    return (
        <div className="add-category">
            <div className="add-product">
                <div className="admin-page-container">
                    <div className="addproduct-itemfield">
                        <div className="product-en">
                            <p>Main Category Name (English)</p>
                            <select value={selectedCategory_en} onChange={handleCategoryChange_en}>
                                <option value="" hidden>Select...</option>
                                <option value={newCategory.name_en}>{newCategory.name_en}</option>
                                {categories_en.map(category => (
                                    <option key={category.id} value={category.id}>{category.gen_category}</option>
                                ))}
                            </select>
                        </div>

                        <div className="product-ar" dir="rtl">
                            <p>أسم الصنف الرئيسي (Arabic)</p>
                            <select value={selectedCategory_ar} onChange={handleCategoryChange_ar}>
                                <option value="" hidden>أختر...</option>
                                <option value={newCategory.name_ar}>{newCategory.name_ar}</option>
                                {categories_ar.map(category => (
                                    <option key={category.id} value={category.id}>{category.gen_category}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {error && <p className="text-danger">{error}</p>}
                    <div className="addproduct-itemfield">
                        <div className="product-en">
                            <p>Main Category Name (English)</p>
                            <input
                                value={categoryDetails.category_en}
                                onChange={changeHandler}
                                type="text"
                                name='category_en'
                                placeholder='Category title in English'
                                aria-label="Category Name in English"
                            />
                        </div>
                        <div className="product-ar" dir='rtl'>
                            <p>أسم الصنف الرئيسي (Arabic)</p>
                            <input
                                value={categoryDetails.category_ar}
                                onChange={changeHandler}
                                type="text"
                                name='category_ar'
                                placeholder='أسم الصنف بالعربية'
                                aria-label="Category Name in Arabic"
                            />
                        </div>
                    </div>
                    <div className="addproduct-itemfield">
                        <div className="product-en">
                            <p>Category Name (English)</p>
                            <input
                                value={categoryDetails.categoryName_en}
                                onChange={changeHandler}
                                type="text"
                                name='categoryName_en'
                                placeholder='Category title in English'
                                aria-label="Category Name in English"
                            />
                        </div>
                        <div className="product-ar" dir='rtl'>
                            <p>أسم الصنف (Arabic)</p>
                            <input
                                value={categoryDetails.categoryName_ar}
                                onChange={changeHandler}
                                type="text"
                                name='categoryName_ar'
                                placeholder='أسم الصنف بالعربية'
                                aria-label="Category Name in Arabic"
                            />
                        </div>
                    </div>

                    <div className="addproduct-itemfield">
                        <label htmlFor="file-input">
                            <img
                                className='addproduct-thumnail-img'
                                src={categoryImage ? URL.createObjectURL(categoryImage) : uploadImg}
                                alt="Thumbnail"
                            />
                        </label>
                        <input
                            type="file"
                            name='image'
                            id="file-input"
                            hidden
                            onChange={imageHandler}
                        />
                    </div>

                    <button onClick={addCategory} className='addproduct-btn'>ADD</button>
                </div>
            </div>
        </div>
    );
};

export default AddCategory;
