import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'


const NavDropData3 = () => {
    const { t } = useTranslation();
    const [navDropData3, setNavDropData3] = useState([]);



    useEffect(() => {
        setNavDropData3([
            {
                key: 1,
                nav_d3_category: t("nav_d3_category1"),
                gen_category: t("sec-nav3"),
            },
            {
                key: 2,
                nav_d3_category: t("nav_d3_category2"),
                gen_category: t("sec-nav3"),
            },
            {
                key: 3,
                nav_d3_category: t("nav_d3_category3"),
                gen_category: t("sec-nav3"),
            },
            {
                key: 4,
                nav_d3_category: t("nav_d3_category4"),
                gen_category: t("sec-nav3"),
            }

        ]);
    }, [t]);

    return navDropData3;
}

export default NavDropData3
