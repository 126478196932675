import './ListCategory.css';
import '../AddCategory/AddCategory.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pagination from 'react-bootstrap/Pagination';
import { MdEdit, MdDeleteForever } from "react-icons/md";

const ListCategory = () => {

    const [categories, setCategories] = useState([]);
    const [categoryDetails, setCategoryDetails] = useState({ categoryName_en: '', categoryName_ar: '' });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(7);
    const [showEdit, setShowEdit] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const token = sessionStorage.getItem('token');

    const fetchCategories = () => {
        if (token) {
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/categories/sub-categories?lang=ar`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    setCategories(response.data);
                })
                .catch(error => console.error('Error fetching data:', error));
        }
    };

    useEffect(() => {
        fetchCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const deleteCategory = (category) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete the category ${category.name}?`);
        if (isConfirmed && token) {
            axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/categories/${category.name}/delete/${category.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (response.status === 200) {
                        setCategories(categories.filter(c => c.id !== category.id));
                    } else {
                        console.error('Error deleting category:', response);
                    }
                })
                .catch(error => {
                    console.error('Error deleting category:', error);
                });
        }
    };

    const handleEditClick = (category) => {

        setSelectedCategoryId(category.id);
        setCategoryDetails({
            categoryName_en: category.name_en,
            categoryName_ar: category.name_ar
        });
        setShowEdit(true);
    };

    const closeEdit = () => {
        setShowEdit(false);
        setSelectedCategoryId(null);
        setCategoryDetails({ categoryName_en: '', categoryName_ar: '' });
    };

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setCategoryDetails(prevDetails => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const modifyCategory = () => {
        if (!selectedCategoryId) return;

        const data = {
            categoryName_en: categoryDetails.categoryName_en,
            categoryName_ar: categoryDetails.categoryName_ar
        };



        axios.put(`${process.env.REACT_APP_BASE_URL}/api/v1/products/category/modify/${selectedCategoryId}`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.status === 200) {
                    const updatedCategory = response.data.category;
                    setCategories(categories.map(category =>
                        category.id === selectedCategoryId
                            ? { ...category, name_en: updatedCategory.name_en, name_ar: updatedCategory.name_ar }
                            : category
                    ));
                    closeEdit();
                    fetchCategories();
                } else {
                    console.error('Error modifying category:', response);
                }
            })
            .catch(error => {
                console.error('Error modifying category:', error);
            });
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPage]);

    const renderPagination = () => {
        if (totalPages <= 1) return null;

        const paginationItems = [];

        // First page
        paginationItems.push(
            <Pagination.Item key={1} active={currentPage === 1} onClick={() => handlePaginationClick(1)}>
                1
            </Pagination.Item>
        );

        // Ellipsis before current page if needed
        if (currentPage > 2) {
            paginationItems.push(<Pagination.Ellipsis key="start-ellipsis" />);
        }

        // Current page
        if (currentPage > 1 && currentPage <= totalPages - 1) {
            paginationItems.push(
                <Pagination.Item key={currentPage} active onClick={() => handlePaginationClick(currentPage)}>
                    {currentPage}
                </Pagination.Item>
            );
        }

        // Ellipsis after current page if needed
        if (currentPage < totalPages - 1) {
            paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" />);
        }

        // Last page
        if (totalPages > 1) {
            paginationItems.push(
                <Pagination.Item key={totalPages} active={currentPage === totalPages} onClick={() => handlePaginationClick(totalPages)}>
                    {totalPages}
                </Pagination.Item>
            );
        }

        return paginationItems;
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = categories.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate page numbers
    const totalPages = Math.ceil(categories.length / itemsPerPage);

    const handlePaginationClick = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className='categoriesT'>
            <table>
                <thead>
                    <tr>
                        <th>أسم الصنف</th>
                        <th>تعديل</th>
                        <th>حذف</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((category, index) => (
                        <tr key={index}>
                            <td>{category.name}</td>
                            <td>
                                <button className='edit' onClick={() => handleEditClick(category)}>
                                    <MdEdit />
                                </button>
                            </td>
                            <td>
                                <button className='delete' style={{ color: "red" }} onClick={() => deleteCategory(category)}>
                                    <MdDeleteForever />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='PagiNation'>
                {totalPages > 1 && (
                    <Pagination>
                        <Pagination.First onClick={() => handlePaginationClick(currentPage - 1)} disabled={currentPage === 1} />
                        {renderPagination()}
                        <Pagination.Last onClick={() => handlePaginationClick(currentPage + 1)} disabled={currentPage === totalPages} />
                    </Pagination>
                )}
            </div>
            {showEdit && (
                <div className="modal-overlay" style={{ zIndex: "10" }} onClick={closeEdit}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className='model-head'>
                            <div>
                                <h5>Edit Category</h5>
                            </div>
                            <div>
                                <span className="close-btn" onClick={closeEdit}>&times;</span>
                            </div>
                        </div>
                        <div className="add-category">
                            <div className="add-product">
                                <div className="admin-page-container">
                                    <div className="addproduct-itemfield">
                                        <div className="product-en">
                                            <p>Category Name (English)</p>
                                            <input
                                                value={categoryDetails.categoryName_en}
                                                onChange={changeHandler}
                                                type="text"
                                                name='categoryName_en'
                                                placeholder='Category title in English'
                                                aria-label="Category Name in English"
                                            />
                                        </div>
                                        <div className="product-ar" dir='rtl'>
                                            <p>أسم الصنف (Arabic)</p>
                                            <input
                                                value={categoryDetails.categoryName_ar}
                                                onChange={changeHandler}
                                                type="text"
                                                name='categoryName_ar'
                                                placeholder='أسم الصنف بالعربية'
                                                aria-label="Category Name in Arabic"
                                            />
                                        </div>
                                    </div>
                                    <button onClick={modifyCategory} className='addproduct-btn'>Edit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ListCategory;
